var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var dingbats = [{
  "Typeface name": "Symbol",
  "Dingbat dec": "32",
  "Dingbat hex": "20",
  "Unicode dec": "32",
  "Unicode hex": "20"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "33",
  "Dingbat hex": "21",
  "Unicode dec": "33",
  "Unicode hex": "21"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "34",
  "Dingbat hex": "22",
  "Unicode dec": "8704",
  "Unicode hex": "2200"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "35",
  "Dingbat hex": "23",
  "Unicode dec": "35",
  "Unicode hex": "23"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "36",
  "Dingbat hex": "24",
  "Unicode dec": "8707",
  "Unicode hex": "2203"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "37",
  "Dingbat hex": "25",
  "Unicode dec": "37",
  "Unicode hex": "25"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "38",
  "Dingbat hex": "26",
  "Unicode dec": "38",
  "Unicode hex": "26"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "39",
  "Dingbat hex": "27",
  "Unicode dec": "8717",
  "Unicode hex": "220D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "40",
  "Dingbat hex": "28",
  "Unicode dec": "40",
  "Unicode hex": "28"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "41",
  "Dingbat hex": "29",
  "Unicode dec": "41",
  "Unicode hex": "29"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "42",
  "Dingbat hex": "2A",
  "Unicode dec": "42",
  "Unicode hex": "2A"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "43",
  "Dingbat hex": "2B",
  "Unicode dec": "43",
  "Unicode hex": "2B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "44",
  "Dingbat hex": "2C",
  "Unicode dec": "44",
  "Unicode hex": "2C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "45",
  "Dingbat hex": "2D",
  "Unicode dec": "8722",
  "Unicode hex": "2212"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "46",
  "Dingbat hex": "2E",
  "Unicode dec": "46",
  "Unicode hex": "2E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "47",
  "Dingbat hex": "2F",
  "Unicode dec": "47",
  "Unicode hex": "2F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "48",
  "Dingbat hex": "30",
  "Unicode dec": "48",
  "Unicode hex": "30"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "49",
  "Dingbat hex": "31",
  "Unicode dec": "49",
  "Unicode hex": "31"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "50",
  "Dingbat hex": "32",
  "Unicode dec": "50",
  "Unicode hex": "32"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "51",
  "Dingbat hex": "33",
  "Unicode dec": "51",
  "Unicode hex": "33"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "52",
  "Dingbat hex": "34",
  "Unicode dec": "52",
  "Unicode hex": "34"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "53",
  "Dingbat hex": "35",
  "Unicode dec": "53",
  "Unicode hex": "35"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "54",
  "Dingbat hex": "36",
  "Unicode dec": "54",
  "Unicode hex": "36"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "55",
  "Dingbat hex": "37",
  "Unicode dec": "55",
  "Unicode hex": "37"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "56",
  "Dingbat hex": "38",
  "Unicode dec": "56",
  "Unicode hex": "38"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "57",
  "Dingbat hex": "39",
  "Unicode dec": "57",
  "Unicode hex": "39"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "58",
  "Dingbat hex": "3A",
  "Unicode dec": "58",
  "Unicode hex": "3A"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "59",
  "Dingbat hex": "3B",
  "Unicode dec": "59",
  "Unicode hex": "3B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "60",
  "Dingbat hex": "3C",
  "Unicode dec": "60",
  "Unicode hex": "3C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "61",
  "Dingbat hex": "3D",
  "Unicode dec": "61",
  "Unicode hex": "3D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "62",
  "Dingbat hex": "3E",
  "Unicode dec": "62",
  "Unicode hex": "3E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "63",
  "Dingbat hex": "3F",
  "Unicode dec": "63",
  "Unicode hex": "3F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "64",
  "Dingbat hex": "40",
  "Unicode dec": "8773",
  "Unicode hex": "2245"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "65",
  "Dingbat hex": "41",
  "Unicode dec": "913",
  "Unicode hex": "391"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "66",
  "Dingbat hex": "42",
  "Unicode dec": "914",
  "Unicode hex": "392"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "67",
  "Dingbat hex": "43",
  "Unicode dec": "935",
  "Unicode hex": "3A7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "68",
  "Dingbat hex": "44",
  "Unicode dec": "916",
  "Unicode hex": "394"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "69",
  "Dingbat hex": "45",
  "Unicode dec": "917",
  "Unicode hex": "395"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "70",
  "Dingbat hex": "46",
  "Unicode dec": "934",
  "Unicode hex": "3A6"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "71",
  "Dingbat hex": "47",
  "Unicode dec": "915",
  "Unicode hex": "393"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "72",
  "Dingbat hex": "48",
  "Unicode dec": "919",
  "Unicode hex": "397"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "73",
  "Dingbat hex": "49",
  "Unicode dec": "921",
  "Unicode hex": "399"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "74",
  "Dingbat hex": "4A",
  "Unicode dec": "977",
  "Unicode hex": "3D1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "75",
  "Dingbat hex": "4B",
  "Unicode dec": "922",
  "Unicode hex": "39A"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "76",
  "Dingbat hex": "4C",
  "Unicode dec": "923",
  "Unicode hex": "39B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "77",
  "Dingbat hex": "4D",
  "Unicode dec": "924",
  "Unicode hex": "39C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "78",
  "Dingbat hex": "4E",
  "Unicode dec": "925",
  "Unicode hex": "39D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "79",
  "Dingbat hex": "4F",
  "Unicode dec": "927",
  "Unicode hex": "39F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "80",
  "Dingbat hex": "50",
  "Unicode dec": "928",
  "Unicode hex": "3A0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "81",
  "Dingbat hex": "51",
  "Unicode dec": "920",
  "Unicode hex": "398"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "82",
  "Dingbat hex": "52",
  "Unicode dec": "929",
  "Unicode hex": "3A1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "83",
  "Dingbat hex": "53",
  "Unicode dec": "931",
  "Unicode hex": "3A3"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "84",
  "Dingbat hex": "54",
  "Unicode dec": "932",
  "Unicode hex": "3A4"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "85",
  "Dingbat hex": "55",
  "Unicode dec": "933",
  "Unicode hex": "3A5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "86",
  "Dingbat hex": "56",
  "Unicode dec": "962",
  "Unicode hex": "3C2"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "87",
  "Dingbat hex": "57",
  "Unicode dec": "937",
  "Unicode hex": "3A9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "88",
  "Dingbat hex": "58",
  "Unicode dec": "926",
  "Unicode hex": "39E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "89",
  "Dingbat hex": "59",
  "Unicode dec": "936",
  "Unicode hex": "3A8"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "90",
  "Dingbat hex": "5A",
  "Unicode dec": "918",
  "Unicode hex": "396"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "91",
  "Dingbat hex": "5B",
  "Unicode dec": "91",
  "Unicode hex": "5B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "92",
  "Dingbat hex": "5C",
  "Unicode dec": "8756",
  "Unicode hex": "2234"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "93",
  "Dingbat hex": "5D",
  "Unicode dec": "93",
  "Unicode hex": "5D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "94",
  "Dingbat hex": "5E",
  "Unicode dec": "8869",
  "Unicode hex": "22A5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "95",
  "Dingbat hex": "5F",
  "Unicode dec": "95",
  "Unicode hex": "5F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "96",
  "Dingbat hex": "60",
  "Unicode dec": "8254",
  "Unicode hex": "203E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "97",
  "Dingbat hex": "61",
  "Unicode dec": "945",
  "Unicode hex": "3B1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "98",
  "Dingbat hex": "62",
  "Unicode dec": "946",
  "Unicode hex": "3B2"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "99",
  "Dingbat hex": "63",
  "Unicode dec": "967",
  "Unicode hex": "3C7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "100",
  "Dingbat hex": "64",
  "Unicode dec": "948",
  "Unicode hex": "3B4"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "101",
  "Dingbat hex": "65",
  "Unicode dec": "949",
  "Unicode hex": "3B5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "102",
  "Dingbat hex": "66",
  "Unicode dec": "966",
  "Unicode hex": "3C6"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "103",
  "Dingbat hex": "67",
  "Unicode dec": "947",
  "Unicode hex": "3B3"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "104",
  "Dingbat hex": "68",
  "Unicode dec": "951",
  "Unicode hex": "3B7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "105",
  "Dingbat hex": "69",
  "Unicode dec": "953",
  "Unicode hex": "3B9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "106",
  "Dingbat hex": "6A",
  "Unicode dec": "981",
  "Unicode hex": "3D5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "107",
  "Dingbat hex": "6B",
  "Unicode dec": "954",
  "Unicode hex": "3BA"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "108",
  "Dingbat hex": "6C",
  "Unicode dec": "955",
  "Unicode hex": "3BB"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "109",
  "Dingbat hex": "6D",
  "Unicode dec": "956",
  "Unicode hex": "3BC"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "110",
  "Dingbat hex": "6E",
  "Unicode dec": "957",
  "Unicode hex": "3BD"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "111",
  "Dingbat hex": "6F",
  "Unicode dec": "959",
  "Unicode hex": "3BF"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "112",
  "Dingbat hex": "70",
  "Unicode dec": "960",
  "Unicode hex": "3C0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "113",
  "Dingbat hex": "71",
  "Unicode dec": "952",
  "Unicode hex": "3B8"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "114",
  "Dingbat hex": "72",
  "Unicode dec": "961",
  "Unicode hex": "3C1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "115",
  "Dingbat hex": "73",
  "Unicode dec": "963",
  "Unicode hex": "3C3"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "116",
  "Dingbat hex": "74",
  "Unicode dec": "964",
  "Unicode hex": "3C4"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "117",
  "Dingbat hex": "75",
  "Unicode dec": "965",
  "Unicode hex": "3C5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "118",
  "Dingbat hex": "76",
  "Unicode dec": "982",
  "Unicode hex": "3D6"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "119",
  "Dingbat hex": "77",
  "Unicode dec": "969",
  "Unicode hex": "3C9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "120",
  "Dingbat hex": "78",
  "Unicode dec": "958",
  "Unicode hex": "3BE"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "121",
  "Dingbat hex": "79",
  "Unicode dec": "968",
  "Unicode hex": "3C8"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "122",
  "Dingbat hex": "7A",
  "Unicode dec": "950",
  "Unicode hex": "3B6"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "123",
  "Dingbat hex": "7B",
  "Unicode dec": "123",
  "Unicode hex": "7B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "124",
  "Dingbat hex": "7C",
  "Unicode dec": "124",
  "Unicode hex": "7C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "125",
  "Dingbat hex": "7D",
  "Unicode dec": "125",
  "Unicode hex": "7D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "126",
  "Dingbat hex": "7E",
  "Unicode dec": "126",
  "Unicode hex": "7E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "160",
  "Dingbat hex": "A0",
  "Unicode dec": "8364",
  "Unicode hex": "20AC"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "161",
  "Dingbat hex": "A1",
  "Unicode dec": "978",
  "Unicode hex": "3D2"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "162",
  "Dingbat hex": "A2",
  "Unicode dec": "8242",
  "Unicode hex": "2032"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "163",
  "Dingbat hex": "A3",
  "Unicode dec": "8804",
  "Unicode hex": "2264"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "164",
  "Dingbat hex": "A4",
  "Unicode dec": "8260",
  "Unicode hex": "2044"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "165",
  "Dingbat hex": "A5",
  "Unicode dec": "8734",
  "Unicode hex": "221E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "166",
  "Dingbat hex": "A6",
  "Unicode dec": "402",
  "Unicode hex": "192"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "167",
  "Dingbat hex": "A7",
  "Unicode dec": "9827",
  "Unicode hex": "2663"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "168",
  "Dingbat hex": "A8",
  "Unicode dec": "9830",
  "Unicode hex": "2666"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "169",
  "Dingbat hex": "A9",
  "Unicode dec": "9829",
  "Unicode hex": "2665"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "170",
  "Dingbat hex": "AA",
  "Unicode dec": "9824",
  "Unicode hex": "2660"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "171",
  "Dingbat hex": "AB",
  "Unicode dec": "8596",
  "Unicode hex": "2194"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "172",
  "Dingbat hex": "AC",
  "Unicode dec": "8592",
  "Unicode hex": "2190"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "173",
  "Dingbat hex": "AD",
  "Unicode dec": "8593",
  "Unicode hex": "2191"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "174",
  "Dingbat hex": "AE",
  "Unicode dec": "8594",
  "Unicode hex": "2192"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "175",
  "Dingbat hex": "AF",
  "Unicode dec": "8595",
  "Unicode hex": "2193"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "176",
  "Dingbat hex": "B0",
  "Unicode dec": "176",
  "Unicode hex": "B0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "177",
  "Dingbat hex": "B1",
  "Unicode dec": "177",
  "Unicode hex": "B1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "178",
  "Dingbat hex": "B2",
  "Unicode dec": "8243",
  "Unicode hex": "2033"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "179",
  "Dingbat hex": "B3",
  "Unicode dec": "8805",
  "Unicode hex": "2265"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "180",
  "Dingbat hex": "B4",
  "Unicode dec": "215",
  "Unicode hex": "D7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "181",
  "Dingbat hex": "B5",
  "Unicode dec": "8733",
  "Unicode hex": "221D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "182",
  "Dingbat hex": "B6",
  "Unicode dec": "8706",
  "Unicode hex": "2202"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "183",
  "Dingbat hex": "B7",
  "Unicode dec": "8226",
  "Unicode hex": "2022"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "184",
  "Dingbat hex": "B8",
  "Unicode dec": "247",
  "Unicode hex": "F7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "185",
  "Dingbat hex": "B9",
  "Unicode dec": "8800",
  "Unicode hex": "2260"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "186",
  "Dingbat hex": "BA",
  "Unicode dec": "8801",
  "Unicode hex": "2261"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "187",
  "Dingbat hex": "BB",
  "Unicode dec": "8776",
  "Unicode hex": "2248"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "188",
  "Dingbat hex": "BC",
  "Unicode dec": "8230",
  "Unicode hex": "2026"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "189",
  "Dingbat hex": "BD",
  "Unicode dec": "9168",
  "Unicode hex": "23D0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "190",
  "Dingbat hex": "BE",
  "Unicode dec": "9135",
  "Unicode hex": "23AF"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "191",
  "Dingbat hex": "BF",
  "Unicode dec": "8629",
  "Unicode hex": "21B5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "192",
  "Dingbat hex": "C0",
  "Unicode dec": "8501",
  "Unicode hex": "2135"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "193",
  "Dingbat hex": "C1",
  "Unicode dec": "8465",
  "Unicode hex": "2111"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "194",
  "Dingbat hex": "C2",
  "Unicode dec": "8476",
  "Unicode hex": "211C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "195",
  "Dingbat hex": "C3",
  "Unicode dec": "8472",
  "Unicode hex": "2118"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "196",
  "Dingbat hex": "C4",
  "Unicode dec": "8855",
  "Unicode hex": "2297"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "197",
  "Dingbat hex": "C5",
  "Unicode dec": "8853",
  "Unicode hex": "2295"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "198",
  "Dingbat hex": "C6",
  "Unicode dec": "8709",
  "Unicode hex": "2205"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "199",
  "Dingbat hex": "C7",
  "Unicode dec": "8745",
  "Unicode hex": "2229"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "200",
  "Dingbat hex": "C8",
  "Unicode dec": "8746",
  "Unicode hex": "222A"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "201",
  "Dingbat hex": "C9",
  "Unicode dec": "8835",
  "Unicode hex": "2283"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "202",
  "Dingbat hex": "CA",
  "Unicode dec": "8839",
  "Unicode hex": "2287"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "203",
  "Dingbat hex": "CB",
  "Unicode dec": "8836",
  "Unicode hex": "2284"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "204",
  "Dingbat hex": "CC",
  "Unicode dec": "8834",
  "Unicode hex": "2282"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "205",
  "Dingbat hex": "CD",
  "Unicode dec": "8838",
  "Unicode hex": "2286"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "206",
  "Dingbat hex": "CE",
  "Unicode dec": "8712",
  "Unicode hex": "2208"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "207",
  "Dingbat hex": "CF",
  "Unicode dec": "8713",
  "Unicode hex": "2209"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "208",
  "Dingbat hex": "D0",
  "Unicode dec": "8736",
  "Unicode hex": "2220"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "209",
  "Dingbat hex": "D1",
  "Unicode dec": "8711",
  "Unicode hex": "2207"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "210",
  "Dingbat hex": "D2",
  "Unicode dec": "174",
  "Unicode hex": "AE"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "211",
  "Dingbat hex": "D3",
  "Unicode dec": "169",
  "Unicode hex": "A9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "212",
  "Dingbat hex": "D4",
  "Unicode dec": "8482",
  "Unicode hex": "2122"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "213",
  "Dingbat hex": "D5",
  "Unicode dec": "8719",
  "Unicode hex": "220F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "214",
  "Dingbat hex": "D6",
  "Unicode dec": "8730",
  "Unicode hex": "221A"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "215",
  "Dingbat hex": "D7",
  "Unicode dec": "8901",
  "Unicode hex": "22C5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "216",
  "Dingbat hex": "D8",
  "Unicode dec": "172",
  "Unicode hex": "AC"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "217",
  "Dingbat hex": "D9",
  "Unicode dec": "8743",
  "Unicode hex": "2227"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "218",
  "Dingbat hex": "DA",
  "Unicode dec": "8744",
  "Unicode hex": "2228"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "219",
  "Dingbat hex": "DB",
  "Unicode dec": "8660",
  "Unicode hex": "21D4"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "220",
  "Dingbat hex": "DC",
  "Unicode dec": "8656",
  "Unicode hex": "21D0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "221",
  "Dingbat hex": "DD",
  "Unicode dec": "8657",
  "Unicode hex": "21D1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "222",
  "Dingbat hex": "DE",
  "Unicode dec": "8658",
  "Unicode hex": "21D2"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "223",
  "Dingbat hex": "DF",
  "Unicode dec": "8659",
  "Unicode hex": "21D3"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "224",
  "Dingbat hex": "E0",
  "Unicode dec": "9674",
  "Unicode hex": "25CA"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "225",
  "Dingbat hex": "E1",
  "Unicode dec": "12296",
  "Unicode hex": "3008"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "226",
  "Dingbat hex": "E2",
  "Unicode dec": "174",
  "Unicode hex": "AE"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "227",
  "Dingbat hex": "E3",
  "Unicode dec": "169",
  "Unicode hex": "A9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "228",
  "Dingbat hex": "E4",
  "Unicode dec": "8482",
  "Unicode hex": "2122"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "229",
  "Dingbat hex": "E5",
  "Unicode dec": "8721",
  "Unicode hex": "2211"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "230",
  "Dingbat hex": "E6",
  "Unicode dec": "9115",
  "Unicode hex": "239B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "231",
  "Dingbat hex": "E7",
  "Unicode dec": "9116",
  "Unicode hex": "239C"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "232",
  "Dingbat hex": "E8",
  "Unicode dec": "9117",
  "Unicode hex": "239D"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "233",
  "Dingbat hex": "E9",
  "Unicode dec": "9121",
  "Unicode hex": "23A1"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "234",
  "Dingbat hex": "EA",
  "Unicode dec": "9122",
  "Unicode hex": "23A2"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "235",
  "Dingbat hex": "EB",
  "Unicode dec": "9123",
  "Unicode hex": "23A3"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "236",
  "Dingbat hex": "EC",
  "Unicode dec": "9127",
  "Unicode hex": "23A7"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "237",
  "Dingbat hex": "ED",
  "Unicode dec": "9128",
  "Unicode hex": "23A8"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "238",
  "Dingbat hex": "EE",
  "Unicode dec": "9129",
  "Unicode hex": "23A9"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "239",
  "Dingbat hex": "EF",
  "Unicode dec": "9130",
  "Unicode hex": "23AA"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "240",
  "Dingbat hex": "F0",
  "Unicode dec": "63743",
  "Unicode hex": "F8FF"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "241",
  "Dingbat hex": "F1",
  "Unicode dec": "12297",
  "Unicode hex": "3009"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "242",
  "Dingbat hex": "F2",
  "Unicode dec": "8747",
  "Unicode hex": "222B"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "243",
  "Dingbat hex": "F3",
  "Unicode dec": "8992",
  "Unicode hex": "2320"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "244",
  "Dingbat hex": "F4",
  "Unicode dec": "9134",
  "Unicode hex": "23AE"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "245",
  "Dingbat hex": "F5",
  "Unicode dec": "8993",
  "Unicode hex": "2321"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "246",
  "Dingbat hex": "F6",
  "Unicode dec": "9118",
  "Unicode hex": "239E"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "247",
  "Dingbat hex": "F7",
  "Unicode dec": "9119",
  "Unicode hex": "239F"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "248",
  "Dingbat hex": "F8",
  "Unicode dec": "9120",
  "Unicode hex": "23A0"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "249",
  "Dingbat hex": "F9",
  "Unicode dec": "9124",
  "Unicode hex": "23A4"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "250",
  "Dingbat hex": "FA",
  "Unicode dec": "9125",
  "Unicode hex": "23A5"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "251",
  "Dingbat hex": "FB",
  "Unicode dec": "9126",
  "Unicode hex": "23A6"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "252",
  "Dingbat hex": "FC",
  "Unicode dec": "9131",
  "Unicode hex": "23AB"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "253",
  "Dingbat hex": "FD",
  "Unicode dec": "9132",
  "Unicode hex": "23AC"
}, {
  "Typeface name": "Symbol",
  "Dingbat dec": "254",
  "Dingbat hex": "FE",
  "Unicode dec": "9133",
  "Unicode hex": "23AD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "32",
  "Dingbat hex": "20",
  "Unicode dec": "32",
  "Unicode hex": "20"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "33",
  "Dingbat hex": "21",
  "Unicode dec": "128375",
  "Unicode hex": "1F577"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "34",
  "Dingbat hex": "22",
  "Unicode dec": "128376",
  "Unicode hex": "1F578"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "35",
  "Dingbat hex": "23",
  "Unicode dec": "128370",
  "Unicode hex": "1F572"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "36",
  "Dingbat hex": "24",
  "Unicode dec": "128374",
  "Unicode hex": "1F576"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "37",
  "Dingbat hex": "25",
  "Unicode dec": "127942",
  "Unicode hex": "1F3C6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "38",
  "Dingbat hex": "26",
  "Unicode dec": "127894",
  "Unicode hex": "1F396"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "39",
  "Dingbat hex": "27",
  "Unicode dec": "128391",
  "Unicode hex": "1F587"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "40",
  "Dingbat hex": "28",
  "Unicode dec": "128488",
  "Unicode hex": "1F5E8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "41",
  "Dingbat hex": "29",
  "Unicode dec": "128489",
  "Unicode hex": "1F5E9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "42",
  "Dingbat hex": "2A",
  "Unicode dec": "128496",
  "Unicode hex": "1F5F0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "43",
  "Dingbat hex": "2B",
  "Unicode dec": "128497",
  "Unicode hex": "1F5F1"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "44",
  "Dingbat hex": "2C",
  "Unicode dec": "127798",
  "Unicode hex": "1F336"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "45",
  "Dingbat hex": "2D",
  "Unicode dec": "127895",
  "Unicode hex": "1F397"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "46",
  "Dingbat hex": "2E",
  "Unicode dec": "128638",
  "Unicode hex": "1F67E"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "47",
  "Dingbat hex": "2F",
  "Unicode dec": "128636",
  "Unicode hex": "1F67C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "48",
  "Dingbat hex": "30",
  "Unicode dec": "128469",
  "Unicode hex": "1F5D5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "49",
  "Dingbat hex": "31",
  "Unicode dec": "128470",
  "Unicode hex": "1F5D6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "50",
  "Dingbat hex": "32",
  "Unicode dec": "128471",
  "Unicode hex": "1F5D7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "51",
  "Dingbat hex": "33",
  "Unicode dec": "9204",
  "Unicode hex": "23F4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "52",
  "Dingbat hex": "34",
  "Unicode dec": "9205",
  "Unicode hex": "23F5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "53",
  "Dingbat hex": "35",
  "Unicode dec": "9206",
  "Unicode hex": "23F6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "54",
  "Dingbat hex": "36",
  "Unicode dec": "9207",
  "Unicode hex": "23F7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "55",
  "Dingbat hex": "37",
  "Unicode dec": "9194",
  "Unicode hex": "23EA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "56",
  "Dingbat hex": "38",
  "Unicode dec": "9193",
  "Unicode hex": "23E9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "57",
  "Dingbat hex": "39",
  "Unicode dec": "9198",
  "Unicode hex": "23EE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "58",
  "Dingbat hex": "3A",
  "Unicode dec": "9197",
  "Unicode hex": "23ED"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "59",
  "Dingbat hex": "3B",
  "Unicode dec": "9208",
  "Unicode hex": "23F8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "60",
  "Dingbat hex": "3C",
  "Unicode dec": "9209",
  "Unicode hex": "23F9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "61",
  "Dingbat hex": "3D",
  "Unicode dec": "9210",
  "Unicode hex": "23FA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "62",
  "Dingbat hex": "3E",
  "Unicode dec": "128474",
  "Unicode hex": "1F5DA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "63",
  "Dingbat hex": "3F",
  "Unicode dec": "128499",
  "Unicode hex": "1F5F3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "64",
  "Dingbat hex": "40",
  "Unicode dec": "128736",
  "Unicode hex": "1F6E0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "65",
  "Dingbat hex": "41",
  "Unicode dec": "127959",
  "Unicode hex": "1F3D7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "66",
  "Dingbat hex": "42",
  "Unicode dec": "127960",
  "Unicode hex": "1F3D8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "67",
  "Dingbat hex": "43",
  "Unicode dec": "127961",
  "Unicode hex": "1F3D9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "68",
  "Dingbat hex": "44",
  "Unicode dec": "127962",
  "Unicode hex": "1F3DA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "69",
  "Dingbat hex": "45",
  "Unicode dec": "127964",
  "Unicode hex": "1F3DC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "70",
  "Dingbat hex": "46",
  "Unicode dec": "127981",
  "Unicode hex": "1F3ED"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "71",
  "Dingbat hex": "47",
  "Unicode dec": "127963",
  "Unicode hex": "1F3DB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "72",
  "Dingbat hex": "48",
  "Unicode dec": "127968",
  "Unicode hex": "1F3E0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "73",
  "Dingbat hex": "49",
  "Unicode dec": "127958",
  "Unicode hex": "1F3D6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "74",
  "Dingbat hex": "4A",
  "Unicode dec": "127965",
  "Unicode hex": "1F3DD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "75",
  "Dingbat hex": "4B",
  "Unicode dec": "128739",
  "Unicode hex": "1F6E3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "76",
  "Dingbat hex": "4C",
  "Unicode dec": "128269",
  "Unicode hex": "1F50D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "77",
  "Dingbat hex": "4D",
  "Unicode dec": "127956",
  "Unicode hex": "1F3D4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "78",
  "Dingbat hex": "4E",
  "Unicode dec": "128065",
  "Unicode hex": "1F441"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "79",
  "Dingbat hex": "4F",
  "Unicode dec": "128066",
  "Unicode hex": "1F442"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "80",
  "Dingbat hex": "50",
  "Unicode dec": "127966",
  "Unicode hex": "1F3DE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "81",
  "Dingbat hex": "51",
  "Unicode dec": "127957",
  "Unicode hex": "1F3D5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "82",
  "Dingbat hex": "52",
  "Unicode dec": "128740",
  "Unicode hex": "1F6E4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "83",
  "Dingbat hex": "53",
  "Unicode dec": "127967",
  "Unicode hex": "1F3DF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "84",
  "Dingbat hex": "54",
  "Unicode dec": "128755",
  "Unicode hex": "1F6F3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "85",
  "Dingbat hex": "55",
  "Unicode dec": "128364",
  "Unicode hex": "1F56C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "86",
  "Dingbat hex": "56",
  "Unicode dec": "128363",
  "Unicode hex": "1F56B"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "87",
  "Dingbat hex": "57",
  "Unicode dec": "128360",
  "Unicode hex": "1F568"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "88",
  "Dingbat hex": "58",
  "Unicode dec": "128264",
  "Unicode hex": "1F508"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "89",
  "Dingbat hex": "59",
  "Unicode dec": "127892",
  "Unicode hex": "1F394"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "90",
  "Dingbat hex": "5A",
  "Unicode dec": "127893",
  "Unicode hex": "1F395"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "91",
  "Dingbat hex": "5B",
  "Unicode dec": "128492",
  "Unicode hex": "1F5EC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "92",
  "Dingbat hex": "5C",
  "Unicode dec": "128637",
  "Unicode hex": "1F67D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "93",
  "Dingbat hex": "5D",
  "Unicode dec": "128493",
  "Unicode hex": "1F5ED"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "94",
  "Dingbat hex": "5E",
  "Unicode dec": "128490",
  "Unicode hex": "1F5EA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "95",
  "Dingbat hex": "5F",
  "Unicode dec": "128491",
  "Unicode hex": "1F5EB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "96",
  "Dingbat hex": "60",
  "Unicode dec": "11156",
  "Unicode hex": "2B94"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "97",
  "Dingbat hex": "61",
  "Unicode dec": "10004",
  "Unicode hex": "2714"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "98",
  "Dingbat hex": "62",
  "Unicode dec": "128690",
  "Unicode hex": "1F6B2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "99",
  "Dingbat hex": "63",
  "Unicode dec": "11036",
  "Unicode hex": "2B1C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "100",
  "Dingbat hex": "64",
  "Unicode dec": "128737",
  "Unicode hex": "1F6E1"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "101",
  "Dingbat hex": "65",
  "Unicode dec": "128230",
  "Unicode hex": "1F4E6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "102",
  "Dingbat hex": "66",
  "Unicode dec": "128753",
  "Unicode hex": "1F6F1"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "103",
  "Dingbat hex": "67",
  "Unicode dec": "11035",
  "Unicode hex": "2B1B"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "104",
  "Dingbat hex": "68",
  "Unicode dec": "128657",
  "Unicode hex": "1F691"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "105",
  "Dingbat hex": "69",
  "Unicode dec": "128712",
  "Unicode hex": "1F6C8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "106",
  "Dingbat hex": "6A",
  "Unicode dec": "128745",
  "Unicode hex": "1F6E9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "107",
  "Dingbat hex": "6B",
  "Unicode dec": "128752",
  "Unicode hex": "1F6F0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "108",
  "Dingbat hex": "6C",
  "Unicode dec": "128968",
  "Unicode hex": "1F7C8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "109",
  "Dingbat hex": "6D",
  "Unicode dec": "128372",
  "Unicode hex": "1F574"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "110",
  "Dingbat hex": "6E",
  "Unicode dec": "11044",
  "Unicode hex": "2B24"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "111",
  "Dingbat hex": "6F",
  "Unicode dec": "128741",
  "Unicode hex": "1F6E5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "112",
  "Dingbat hex": "70",
  "Unicode dec": "128660",
  "Unicode hex": "1F694"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "113",
  "Dingbat hex": "71",
  "Unicode dec": "128472",
  "Unicode hex": "1F5D8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "114",
  "Dingbat hex": "72",
  "Unicode dec": "128473",
  "Unicode hex": "1F5D9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "115",
  "Dingbat hex": "73",
  "Unicode dec": "10067",
  "Unicode hex": "2753"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "116",
  "Dingbat hex": "74",
  "Unicode dec": "128754",
  "Unicode hex": "1F6F2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "117",
  "Dingbat hex": "75",
  "Unicode dec": "128647",
  "Unicode hex": "1F687"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "118",
  "Dingbat hex": "76",
  "Unicode dec": "128653",
  "Unicode hex": "1F68D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "119",
  "Dingbat hex": "77",
  "Unicode dec": "9971",
  "Unicode hex": "26F3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "120",
  "Dingbat hex": "78",
  "Unicode dec": "10680",
  "Unicode hex": "29B8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "121",
  "Dingbat hex": "79",
  "Unicode dec": "8854",
  "Unicode hex": "2296"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "122",
  "Dingbat hex": "7A",
  "Unicode dec": "128685",
  "Unicode hex": "1F6AD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "123",
  "Dingbat hex": "7B",
  "Unicode dec": "128494",
  "Unicode hex": "1F5EE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "124",
  "Dingbat hex": "7C",
  "Unicode dec": "9168",
  "Unicode hex": "23D0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "125",
  "Dingbat hex": "7D",
  "Unicode dec": "128495",
  "Unicode hex": "1F5EF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "126",
  "Dingbat hex": "7E",
  "Unicode dec": "128498",
  "Unicode hex": "1F5F2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "128",
  "Dingbat hex": "80",
  "Unicode dec": "128697",
  "Unicode hex": "1F6B9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "129",
  "Dingbat hex": "81",
  "Unicode dec": "128698",
  "Unicode hex": "1F6BA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "130",
  "Dingbat hex": "82",
  "Unicode dec": "128713",
  "Unicode hex": "1F6C9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "131",
  "Dingbat hex": "83",
  "Unicode dec": "128714",
  "Unicode hex": "1F6CA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "132",
  "Dingbat hex": "84",
  "Unicode dec": "128700",
  "Unicode hex": "1F6BC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "133",
  "Dingbat hex": "85",
  "Unicode dec": "128125",
  "Unicode hex": "1F47D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "134",
  "Dingbat hex": "86",
  "Unicode dec": "127947",
  "Unicode hex": "1F3CB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "135",
  "Dingbat hex": "87",
  "Unicode dec": "9975",
  "Unicode hex": "26F7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "136",
  "Dingbat hex": "88",
  "Unicode dec": "127938",
  "Unicode hex": "1F3C2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "137",
  "Dingbat hex": "89",
  "Unicode dec": "127948",
  "Unicode hex": "1F3CC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "138",
  "Dingbat hex": "8A",
  "Unicode dec": "127946",
  "Unicode hex": "1F3CA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "139",
  "Dingbat hex": "8B",
  "Unicode dec": "127940",
  "Unicode hex": "1F3C4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "140",
  "Dingbat hex": "8C",
  "Unicode dec": "127949",
  "Unicode hex": "1F3CD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "141",
  "Dingbat hex": "8D",
  "Unicode dec": "127950",
  "Unicode hex": "1F3CE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "142",
  "Dingbat hex": "8E",
  "Unicode dec": "128664",
  "Unicode hex": "1F698"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "143",
  "Dingbat hex": "8F",
  "Unicode dec": "128480",
  "Unicode hex": "1F5E0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "144",
  "Dingbat hex": "90",
  "Unicode dec": "128738",
  "Unicode hex": "1F6E2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "145",
  "Dingbat hex": "91",
  "Unicode dec": "128176",
  "Unicode hex": "1F4B0"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "146",
  "Dingbat hex": "92",
  "Unicode dec": "127991",
  "Unicode hex": "1F3F7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "147",
  "Dingbat hex": "93",
  "Unicode dec": "128179",
  "Unicode hex": "1F4B3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "148",
  "Dingbat hex": "94",
  "Unicode dec": "128106",
  "Unicode hex": "1F46A"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "149",
  "Dingbat hex": "95",
  "Unicode dec": "128481",
  "Unicode hex": "1F5E1"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "150",
  "Dingbat hex": "96",
  "Unicode dec": "128482",
  "Unicode hex": "1F5E2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "151",
  "Dingbat hex": "97",
  "Unicode dec": "128483",
  "Unicode hex": "1F5E3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "152",
  "Dingbat hex": "98",
  "Unicode dec": "10031",
  "Unicode hex": "272F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "153",
  "Dingbat hex": "99",
  "Unicode dec": "128388",
  "Unicode hex": "1F584"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "154",
  "Dingbat hex": "9A",
  "Unicode dec": "128389",
  "Unicode hex": "1F585"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "155",
  "Dingbat hex": "9B",
  "Unicode dec": "128387",
  "Unicode hex": "1F583"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "156",
  "Dingbat hex": "9C",
  "Unicode dec": "128390",
  "Unicode hex": "1F586"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "157",
  "Dingbat hex": "9D",
  "Unicode dec": "128441",
  "Unicode hex": "1F5B9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "158",
  "Dingbat hex": "9E",
  "Unicode dec": "128442",
  "Unicode hex": "1F5BA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "159",
  "Dingbat hex": "9F",
  "Unicode dec": "128443",
  "Unicode hex": "1F5BB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "160",
  "Dingbat hex": "A0",
  "Unicode dec": "128373",
  "Unicode hex": "1F575"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "161",
  "Dingbat hex": "A1",
  "Unicode dec": "128368",
  "Unicode hex": "1F570"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "162",
  "Dingbat hex": "A2",
  "Unicode dec": "128445",
  "Unicode hex": "1F5BD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "163",
  "Dingbat hex": "A3",
  "Unicode dec": "128446",
  "Unicode hex": "1F5BE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "164",
  "Dingbat hex": "A4",
  "Unicode dec": "128203",
  "Unicode hex": "1F4CB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "165",
  "Dingbat hex": "A5",
  "Unicode dec": "128466",
  "Unicode hex": "1F5D2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "166",
  "Dingbat hex": "A6",
  "Unicode dec": "128467",
  "Unicode hex": "1F5D3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "167",
  "Dingbat hex": "A7",
  "Unicode dec": "128366",
  "Unicode hex": "1F56E"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "168",
  "Dingbat hex": "A8",
  "Unicode dec": "128218",
  "Unicode hex": "1F4DA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "169",
  "Dingbat hex": "A9",
  "Unicode dec": "128478",
  "Unicode hex": "1F5DE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "170",
  "Dingbat hex": "AA",
  "Unicode dec": "128479",
  "Unicode hex": "1F5DF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "171",
  "Dingbat hex": "AB",
  "Unicode dec": "128451",
  "Unicode hex": "1F5C3"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "172",
  "Dingbat hex": "AC",
  "Unicode dec": "128450",
  "Unicode hex": "1F5C2"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "173",
  "Dingbat hex": "AD",
  "Unicode dec": "128444",
  "Unicode hex": "1F5BC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "174",
  "Dingbat hex": "AE",
  "Unicode dec": "127917",
  "Unicode hex": "1F3AD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "175",
  "Dingbat hex": "AF",
  "Unicode dec": "127900",
  "Unicode hex": "1F39C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "176",
  "Dingbat hex": "B0",
  "Unicode dec": "127896",
  "Unicode hex": "1F398"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "177",
  "Dingbat hex": "B1",
  "Unicode dec": "127897",
  "Unicode hex": "1F399"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "178",
  "Dingbat hex": "B2",
  "Unicode dec": "127911",
  "Unicode hex": "1F3A7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "179",
  "Dingbat hex": "B3",
  "Unicode dec": "128191",
  "Unicode hex": "1F4BF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "180",
  "Dingbat hex": "B4",
  "Unicode dec": "127902",
  "Unicode hex": "1F39E"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "181",
  "Dingbat hex": "B5",
  "Unicode dec": "128247",
  "Unicode hex": "1F4F7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "182",
  "Dingbat hex": "B6",
  "Unicode dec": "127903",
  "Unicode hex": "1F39F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "183",
  "Dingbat hex": "B7",
  "Unicode dec": "127916",
  "Unicode hex": "1F3AC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "184",
  "Dingbat hex": "B8",
  "Unicode dec": "128253",
  "Unicode hex": "1F4FD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "185",
  "Dingbat hex": "B9",
  "Unicode dec": "128249",
  "Unicode hex": "1F4F9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "186",
  "Dingbat hex": "BA",
  "Unicode dec": "128254",
  "Unicode hex": "1F4FE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "187",
  "Dingbat hex": "BB",
  "Unicode dec": "128251",
  "Unicode hex": "1F4FB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "188",
  "Dingbat hex": "BC",
  "Unicode dec": "127898",
  "Unicode hex": "1F39A"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "189",
  "Dingbat hex": "BD",
  "Unicode dec": "127899",
  "Unicode hex": "1F39B"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "190",
  "Dingbat hex": "BE",
  "Unicode dec": "128250",
  "Unicode hex": "1F4FA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "191",
  "Dingbat hex": "BF",
  "Unicode dec": "128187",
  "Unicode hex": "1F4BB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "192",
  "Dingbat hex": "C0",
  "Unicode dec": "128421",
  "Unicode hex": "1F5A5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "193",
  "Dingbat hex": "C1",
  "Unicode dec": "128422",
  "Unicode hex": "1F5A6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "194",
  "Dingbat hex": "C2",
  "Unicode dec": "128423",
  "Unicode hex": "1F5A7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "195",
  "Dingbat hex": "C3",
  "Unicode dec": "128377",
  "Unicode hex": "1F579"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "196",
  "Dingbat hex": "C4",
  "Unicode dec": "127918",
  "Unicode hex": "1F3AE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "197",
  "Dingbat hex": "C5",
  "Unicode dec": "128379",
  "Unicode hex": "1F57B"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "198",
  "Dingbat hex": "C6",
  "Unicode dec": "128380",
  "Unicode hex": "1F57C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "199",
  "Dingbat hex": "C7",
  "Unicode dec": "128223",
  "Unicode hex": "1F4DF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "200",
  "Dingbat hex": "C8",
  "Unicode dec": "128385",
  "Unicode hex": "1F581"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "201",
  "Dingbat hex": "C9",
  "Unicode dec": "128384",
  "Unicode hex": "1F580"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "202",
  "Dingbat hex": "CA",
  "Unicode dec": "128424",
  "Unicode hex": "1F5A8"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "203",
  "Dingbat hex": "CB",
  "Unicode dec": "128425",
  "Unicode hex": "1F5A9"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "204",
  "Dingbat hex": "CC",
  "Unicode dec": "128447",
  "Unicode hex": "1F5BF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "205",
  "Dingbat hex": "CD",
  "Unicode dec": "128426",
  "Unicode hex": "1F5AA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "206",
  "Dingbat hex": "CE",
  "Unicode dec": "128476",
  "Unicode hex": "1F5DC"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "207",
  "Dingbat hex": "CF",
  "Unicode dec": "128274",
  "Unicode hex": "1F512"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "208",
  "Dingbat hex": "D0",
  "Unicode dec": "128275",
  "Unicode hex": "1F513"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "209",
  "Dingbat hex": "D1",
  "Unicode dec": "128477",
  "Unicode hex": "1F5DD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "210",
  "Dingbat hex": "D2",
  "Unicode dec": "128229",
  "Unicode hex": "1F4E5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "211",
  "Dingbat hex": "D3",
  "Unicode dec": "128228",
  "Unicode hex": "1F4E4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "212",
  "Dingbat hex": "D4",
  "Unicode dec": "128371",
  "Unicode hex": "1F573"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "213",
  "Dingbat hex": "D5",
  "Unicode dec": "127779",
  "Unicode hex": "1F323"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "214",
  "Dingbat hex": "D6",
  "Unicode dec": "127780",
  "Unicode hex": "1F324"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "215",
  "Dingbat hex": "D7",
  "Unicode dec": "127781",
  "Unicode hex": "1F325"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "216",
  "Dingbat hex": "D8",
  "Unicode dec": "127782",
  "Unicode hex": "1F326"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "217",
  "Dingbat hex": "D9",
  "Unicode dec": "9729",
  "Unicode hex": "2601"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "218",
  "Dingbat hex": "DA",
  "Unicode dec": "127784",
  "Unicode hex": "1F328"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "219",
  "Dingbat hex": "DB",
  "Unicode dec": "127783",
  "Unicode hex": "1F327"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "220",
  "Dingbat hex": "DC",
  "Unicode dec": "127785",
  "Unicode hex": "1F329"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "221",
  "Dingbat hex": "DD",
  "Unicode dec": "127786",
  "Unicode hex": "1F32A"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "222",
  "Dingbat hex": "DE",
  "Unicode dec": "127788",
  "Unicode hex": "1F32C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "223",
  "Dingbat hex": "DF",
  "Unicode dec": "127787",
  "Unicode hex": "1F32B"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "224",
  "Dingbat hex": "E0",
  "Unicode dec": "127772",
  "Unicode hex": "1F31C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "225",
  "Dingbat hex": "E1",
  "Unicode dec": "127777",
  "Unicode hex": "1F321"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "226",
  "Dingbat hex": "E2",
  "Unicode dec": "128715",
  "Unicode hex": "1F6CB"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "227",
  "Dingbat hex": "E3",
  "Unicode dec": "128719",
  "Unicode hex": "1F6CF"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "228",
  "Dingbat hex": "E4",
  "Unicode dec": "127869",
  "Unicode hex": "1F37D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "229",
  "Dingbat hex": "E5",
  "Unicode dec": "127864",
  "Unicode hex": "1F378"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "230",
  "Dingbat hex": "E6",
  "Unicode dec": "128718",
  "Unicode hex": "1F6CE"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "231",
  "Dingbat hex": "E7",
  "Unicode dec": "128717",
  "Unicode hex": "1F6CD"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "232",
  "Dingbat hex": "E8",
  "Unicode dec": "9413",
  "Unicode hex": "24C5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "233",
  "Dingbat hex": "E9",
  "Unicode dec": "9855",
  "Unicode hex": "267F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "234",
  "Dingbat hex": "EA",
  "Unicode dec": "128710",
  "Unicode hex": "1F6C6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "235",
  "Dingbat hex": "EB",
  "Unicode dec": "128392",
  "Unicode hex": "1F588"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "236",
  "Dingbat hex": "EC",
  "Unicode dec": "127891",
  "Unicode hex": "1F393"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "237",
  "Dingbat hex": "ED",
  "Unicode dec": "128484",
  "Unicode hex": "1F5E4"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "238",
  "Dingbat hex": "EE",
  "Unicode dec": "128485",
  "Unicode hex": "1F5E5"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "239",
  "Dingbat hex": "EF",
  "Unicode dec": "128486",
  "Unicode hex": "1F5E6"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "240",
  "Dingbat hex": "F0",
  "Unicode dec": "128487",
  "Unicode hex": "1F5E7"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "241",
  "Dingbat hex": "F1",
  "Unicode dec": "128746",
  "Unicode hex": "1F6EA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "242",
  "Dingbat hex": "F2",
  "Unicode dec": "128063",
  "Unicode hex": "1F43F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "243",
  "Dingbat hex": "F3",
  "Unicode dec": "128038",
  "Unicode hex": "1F426"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "244",
  "Dingbat hex": "F4",
  "Unicode dec": "128031",
  "Unicode hex": "1F41F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "245",
  "Dingbat hex": "F5",
  "Unicode dec": "128021",
  "Unicode hex": "1F415"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "246",
  "Dingbat hex": "F6",
  "Unicode dec": "128008",
  "Unicode hex": "1F408"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "247",
  "Dingbat hex": "F7",
  "Unicode dec": "128620",
  "Unicode hex": "1F66C"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "248",
  "Dingbat hex": "F8",
  "Unicode dec": "128622",
  "Unicode hex": "1F66E"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "249",
  "Dingbat hex": "F9",
  "Unicode dec": "128621",
  "Unicode hex": "1F66D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "250",
  "Dingbat hex": "FA",
  "Unicode dec": "128623",
  "Unicode hex": "1F66F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "251",
  "Dingbat hex": "FB",
  "Unicode dec": "128506",
  "Unicode hex": "1F5FA"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "252",
  "Dingbat hex": "FC",
  "Unicode dec": "127757",
  "Unicode hex": "1F30D"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "253",
  "Dingbat hex": "FD",
  "Unicode dec": "127759",
  "Unicode hex": "1F30F"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "254",
  "Dingbat hex": "FE",
  "Unicode dec": "127758",
  "Unicode hex": "1F30E"
}, {
  "Typeface name": "Webdings",
  "Dingbat dec": "255",
  "Dingbat hex": "FF",
  "Unicode dec": "128330",
  "Unicode hex": "1F54A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "32",
  "Dingbat hex": "20",
  "Unicode dec": "32",
  "Unicode hex": "20"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "33",
  "Dingbat hex": "21",
  "Unicode dec": "128393",
  "Unicode hex": "1F589"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "34",
  "Dingbat hex": "22",
  "Unicode dec": "9986",
  "Unicode hex": "2702"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "35",
  "Dingbat hex": "23",
  "Unicode dec": "9985",
  "Unicode hex": "2701"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "36",
  "Dingbat hex": "24",
  "Unicode dec": "128083",
  "Unicode hex": "1F453"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "37",
  "Dingbat hex": "25",
  "Unicode dec": "128365",
  "Unicode hex": "1F56D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "38",
  "Dingbat hex": "26",
  "Unicode dec": "128366",
  "Unicode hex": "1F56E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "39",
  "Dingbat hex": "27",
  "Unicode dec": "128367",
  "Unicode hex": "1F56F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "40",
  "Dingbat hex": "28",
  "Unicode dec": "128383",
  "Unicode hex": "1F57F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "41",
  "Dingbat hex": "29",
  "Unicode dec": "9990",
  "Unicode hex": "2706"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "42",
  "Dingbat hex": "2A",
  "Unicode dec": "128386",
  "Unicode hex": "1F582"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "43",
  "Dingbat hex": "2B",
  "Unicode dec": "128387",
  "Unicode hex": "1F583"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "44",
  "Dingbat hex": "2C",
  "Unicode dec": "128234",
  "Unicode hex": "1F4EA"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "45",
  "Dingbat hex": "2D",
  "Unicode dec": "128235",
  "Unicode hex": "1F4EB"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "46",
  "Dingbat hex": "2E",
  "Unicode dec": "128236",
  "Unicode hex": "1F4EC"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "47",
  "Dingbat hex": "2F",
  "Unicode dec": "128237",
  "Unicode hex": "1F4ED"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "48",
  "Dingbat hex": "30",
  "Unicode dec": "128448",
  "Unicode hex": "1F5C0"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "49",
  "Dingbat hex": "31",
  "Unicode dec": "128449",
  "Unicode hex": "1F5C1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "50",
  "Dingbat hex": "32",
  "Unicode dec": "128462",
  "Unicode hex": "1F5CE"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "51",
  "Dingbat hex": "33",
  "Unicode dec": "128463",
  "Unicode hex": "1F5CF"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "52",
  "Dingbat hex": "34",
  "Unicode dec": "128464",
  "Unicode hex": "1F5D0"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "53",
  "Dingbat hex": "35",
  "Unicode dec": "128452",
  "Unicode hex": "1F5C4"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "54",
  "Dingbat hex": "36",
  "Unicode dec": "8987",
  "Unicode hex": "231B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "55",
  "Dingbat hex": "37",
  "Unicode dec": "128430",
  "Unicode hex": "1F5AE"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "56",
  "Dingbat hex": "38",
  "Unicode dec": "128432",
  "Unicode hex": "1F5B0"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "57",
  "Dingbat hex": "39",
  "Unicode dec": "128434",
  "Unicode hex": "1F5B2"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "58",
  "Dingbat hex": "3A",
  "Unicode dec": "128435",
  "Unicode hex": "1F5B3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "59",
  "Dingbat hex": "3B",
  "Unicode dec": "128436",
  "Unicode hex": "1F5B4"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "60",
  "Dingbat hex": "3C",
  "Unicode dec": "128427",
  "Unicode hex": "1F5AB"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "61",
  "Dingbat hex": "3D",
  "Unicode dec": "128428",
  "Unicode hex": "1F5AC"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "62",
  "Dingbat hex": "3E",
  "Unicode dec": "9991",
  "Unicode hex": "2707"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "63",
  "Dingbat hex": "3F",
  "Unicode dec": "9997",
  "Unicode hex": "270D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "64",
  "Dingbat hex": "40",
  "Unicode dec": "128398",
  "Unicode hex": "1F58E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "65",
  "Dingbat hex": "41",
  "Unicode dec": "9996",
  "Unicode hex": "270C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "66",
  "Dingbat hex": "42",
  "Unicode dec": "128399",
  "Unicode hex": "1F58F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "67",
  "Dingbat hex": "43",
  "Unicode dec": "128077",
  "Unicode hex": "1F44D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "68",
  "Dingbat hex": "44",
  "Unicode dec": "128078",
  "Unicode hex": "1F44E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "69",
  "Dingbat hex": "45",
  "Unicode dec": "9756",
  "Unicode hex": "261C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "70",
  "Dingbat hex": "46",
  "Unicode dec": "9758",
  "Unicode hex": "261E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "71",
  "Dingbat hex": "47",
  "Unicode dec": "9757",
  "Unicode hex": "261D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "72",
  "Dingbat hex": "48",
  "Unicode dec": "9759",
  "Unicode hex": "261F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "73",
  "Dingbat hex": "49",
  "Unicode dec": "128400",
  "Unicode hex": "1F590"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "74",
  "Dingbat hex": "4A",
  "Unicode dec": "9786",
  "Unicode hex": "263A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "75",
  "Dingbat hex": "4B",
  "Unicode dec": "128528",
  "Unicode hex": "1F610"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "76",
  "Dingbat hex": "4C",
  "Unicode dec": "9785",
  "Unicode hex": "2639"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "77",
  "Dingbat hex": "4D",
  "Unicode dec": "128163",
  "Unicode hex": "1F4A3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "78",
  "Dingbat hex": "4E",
  "Unicode dec": "128369",
  "Unicode hex": "1F571"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "79",
  "Dingbat hex": "4F",
  "Unicode dec": "127987",
  "Unicode hex": "1F3F3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "80",
  "Dingbat hex": "50",
  "Unicode dec": "127985",
  "Unicode hex": "1F3F1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "81",
  "Dingbat hex": "51",
  "Unicode dec": "9992",
  "Unicode hex": "2708"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "82",
  "Dingbat hex": "52",
  "Unicode dec": "9788",
  "Unicode hex": "263C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "83",
  "Dingbat hex": "53",
  "Unicode dec": "127778",
  "Unicode hex": "1F322"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "84",
  "Dingbat hex": "54",
  "Unicode dec": "10052",
  "Unicode hex": "2744"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "85",
  "Dingbat hex": "55",
  "Unicode dec": "128326",
  "Unicode hex": "1F546"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "86",
  "Dingbat hex": "56",
  "Unicode dec": "10014",
  "Unicode hex": "271E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "87",
  "Dingbat hex": "57",
  "Unicode dec": "128328",
  "Unicode hex": "1F548"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "88",
  "Dingbat hex": "58",
  "Unicode dec": "10016",
  "Unicode hex": "2720"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "89",
  "Dingbat hex": "59",
  "Unicode dec": "10017",
  "Unicode hex": "2721"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "90",
  "Dingbat hex": "5A",
  "Unicode dec": "9770",
  "Unicode hex": "262A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "91",
  "Dingbat hex": "5B",
  "Unicode dec": "9775",
  "Unicode hex": "262F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "92",
  "Dingbat hex": "5C",
  "Unicode dec": "128329",
  "Unicode hex": "1F549"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "93",
  "Dingbat hex": "5D",
  "Unicode dec": "9784",
  "Unicode hex": "2638"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "94",
  "Dingbat hex": "5E",
  "Unicode dec": "9800",
  "Unicode hex": "2648"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "95",
  "Dingbat hex": "5F",
  "Unicode dec": "9801",
  "Unicode hex": "2649"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "96",
  "Dingbat hex": "60",
  "Unicode dec": "9802",
  "Unicode hex": "264A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "97",
  "Dingbat hex": "61",
  "Unicode dec": "9803",
  "Unicode hex": "264B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "98",
  "Dingbat hex": "62",
  "Unicode dec": "9804",
  "Unicode hex": "264C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "99",
  "Dingbat hex": "63",
  "Unicode dec": "9805",
  "Unicode hex": "264D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "100",
  "Dingbat hex": "64",
  "Unicode dec": "9806",
  "Unicode hex": "264E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "101",
  "Dingbat hex": "65",
  "Unicode dec": "9807",
  "Unicode hex": "264F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "102",
  "Dingbat hex": "66",
  "Unicode dec": "9808",
  "Unicode hex": "2650"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "103",
  "Dingbat hex": "67",
  "Unicode dec": "9809",
  "Unicode hex": "2651"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "104",
  "Dingbat hex": "68",
  "Unicode dec": "9810",
  "Unicode hex": "2652"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "105",
  "Dingbat hex": "69",
  "Unicode dec": "9811",
  "Unicode hex": "2653"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "106",
  "Dingbat hex": "6A",
  "Unicode dec": "128624",
  "Unicode hex": "1F670"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "107",
  "Dingbat hex": "6B",
  "Unicode dec": "128629",
  "Unicode hex": "1F675"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "108",
  "Dingbat hex": "6C",
  "Unicode dec": "9899",
  "Unicode hex": "26AB"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "109",
  "Dingbat hex": "6D",
  "Unicode dec": "128318",
  "Unicode hex": "1F53E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "110",
  "Dingbat hex": "6E",
  "Unicode dec": "9724",
  "Unicode hex": "25FC"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "111",
  "Dingbat hex": "6F",
  "Unicode dec": "128911",
  "Unicode hex": "1F78F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "112",
  "Dingbat hex": "70",
  "Unicode dec": "128912",
  "Unicode hex": "1F790"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "113",
  "Dingbat hex": "71",
  "Unicode dec": "10065",
  "Unicode hex": "2751"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "114",
  "Dingbat hex": "72",
  "Unicode dec": "10066",
  "Unicode hex": "2752"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "115",
  "Dingbat hex": "73",
  "Unicode dec": "128927",
  "Unicode hex": "1F79F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "116",
  "Dingbat hex": "74",
  "Unicode dec": "10731",
  "Unicode hex": "29EB"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "117",
  "Dingbat hex": "75",
  "Unicode dec": "9670",
  "Unicode hex": "25C6"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "118",
  "Dingbat hex": "76",
  "Unicode dec": "10070",
  "Unicode hex": "2756"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "119",
  "Dingbat hex": "77",
  "Unicode dec": "11049",
  "Unicode hex": "2B29"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "120",
  "Dingbat hex": "78",
  "Unicode dec": "8999",
  "Unicode hex": "2327"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "121",
  "Dingbat hex": "79",
  "Unicode dec": "11193",
  "Unicode hex": "2BB9"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "122",
  "Dingbat hex": "7A",
  "Unicode dec": "8984",
  "Unicode hex": "2318"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "123",
  "Dingbat hex": "7B",
  "Unicode dec": "127989",
  "Unicode hex": "1F3F5"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "124",
  "Dingbat hex": "7C",
  "Unicode dec": "127990",
  "Unicode hex": "1F3F6"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "125",
  "Dingbat hex": "7D",
  "Unicode dec": "128630",
  "Unicode hex": "1F676"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "126",
  "Dingbat hex": "7E",
  "Unicode dec": "128631",
  "Unicode hex": "1F677"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "127",
  "Dingbat hex": "7F",
  "Unicode dec": "9647",
  "Unicode hex": "25AF"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "128",
  "Dingbat hex": "80",
  "Unicode dec": "127243",
  "Unicode hex": "1F10B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "129",
  "Dingbat hex": "81",
  "Unicode dec": "10112",
  "Unicode hex": "2780"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "130",
  "Dingbat hex": "82",
  "Unicode dec": "10113",
  "Unicode hex": "2781"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "131",
  "Dingbat hex": "83",
  "Unicode dec": "10114",
  "Unicode hex": "2782"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "132",
  "Dingbat hex": "84",
  "Unicode dec": "10115",
  "Unicode hex": "2783"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "133",
  "Dingbat hex": "85",
  "Unicode dec": "10116",
  "Unicode hex": "2784"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "134",
  "Dingbat hex": "86",
  "Unicode dec": "10117",
  "Unicode hex": "2785"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "135",
  "Dingbat hex": "87",
  "Unicode dec": "10118",
  "Unicode hex": "2786"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "136",
  "Dingbat hex": "88",
  "Unicode dec": "10119",
  "Unicode hex": "2787"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "137",
  "Dingbat hex": "89",
  "Unicode dec": "10120",
  "Unicode hex": "2788"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "138",
  "Dingbat hex": "8A",
  "Unicode dec": "10121",
  "Unicode hex": "2789"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "139",
  "Dingbat hex": "8B",
  "Unicode dec": "127244",
  "Unicode hex": "1F10C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "140",
  "Dingbat hex": "8C",
  "Unicode dec": "10122",
  "Unicode hex": "278A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "141",
  "Dingbat hex": "8D",
  "Unicode dec": "10123",
  "Unicode hex": "278B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "142",
  "Dingbat hex": "8E",
  "Unicode dec": "10124",
  "Unicode hex": "278C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "143",
  "Dingbat hex": "8F",
  "Unicode dec": "10125",
  "Unicode hex": "278D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "144",
  "Dingbat hex": "90",
  "Unicode dec": "10126",
  "Unicode hex": "278E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "145",
  "Dingbat hex": "91",
  "Unicode dec": "10127",
  "Unicode hex": "278F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "146",
  "Dingbat hex": "92",
  "Unicode dec": "10128",
  "Unicode hex": "2790"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "147",
  "Dingbat hex": "93",
  "Unicode dec": "10129",
  "Unicode hex": "2791"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "148",
  "Dingbat hex": "94",
  "Unicode dec": "10130",
  "Unicode hex": "2792"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "149",
  "Dingbat hex": "95",
  "Unicode dec": "10131",
  "Unicode hex": "2793"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "150",
  "Dingbat hex": "96",
  "Unicode dec": "128610",
  "Unicode hex": "1F662"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "151",
  "Dingbat hex": "97",
  "Unicode dec": "128608",
  "Unicode hex": "1F660"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "152",
  "Dingbat hex": "98",
  "Unicode dec": "128609",
  "Unicode hex": "1F661"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "153",
  "Dingbat hex": "99",
  "Unicode dec": "128611",
  "Unicode hex": "1F663"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "154",
  "Dingbat hex": "9A",
  "Unicode dec": "128606",
  "Unicode hex": "1F65E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "155",
  "Dingbat hex": "9B",
  "Unicode dec": "128604",
  "Unicode hex": "1F65C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "156",
  "Dingbat hex": "9C",
  "Unicode dec": "128605",
  "Unicode hex": "1F65D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "157",
  "Dingbat hex": "9D",
  "Unicode dec": "128607",
  "Unicode hex": "1F65F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "158",
  "Dingbat hex": "9E",
  "Unicode dec": "8729",
  "Unicode hex": "2219"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "159",
  "Dingbat hex": "9F",
  "Unicode dec": "8226",
  "Unicode hex": "2022"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "160",
  "Dingbat hex": "A0",
  "Unicode dec": "11037",
  "Unicode hex": "2B1D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "161",
  "Dingbat hex": "A1",
  "Unicode dec": "11096",
  "Unicode hex": "2B58"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "162",
  "Dingbat hex": "A2",
  "Unicode dec": "128902",
  "Unicode hex": "1F786"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "163",
  "Dingbat hex": "A3",
  "Unicode dec": "128904",
  "Unicode hex": "1F788"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "164",
  "Dingbat hex": "A4",
  "Unicode dec": "128906",
  "Unicode hex": "1F78A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "165",
  "Dingbat hex": "A5",
  "Unicode dec": "128907",
  "Unicode hex": "1F78B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "166",
  "Dingbat hex": "A6",
  "Unicode dec": "128319",
  "Unicode hex": "1F53F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "167",
  "Dingbat hex": "A7",
  "Unicode dec": "9642",
  "Unicode hex": "25AA"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "168",
  "Dingbat hex": "A8",
  "Unicode dec": "128910",
  "Unicode hex": "1F78E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "169",
  "Dingbat hex": "A9",
  "Unicode dec": "128961",
  "Unicode hex": "1F7C1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "170",
  "Dingbat hex": "AA",
  "Unicode dec": "128965",
  "Unicode hex": "1F7C5"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "171",
  "Dingbat hex": "AB",
  "Unicode dec": "9733",
  "Unicode hex": "2605"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "172",
  "Dingbat hex": "AC",
  "Unicode dec": "128971",
  "Unicode hex": "1F7CB"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "173",
  "Dingbat hex": "AD",
  "Unicode dec": "128975",
  "Unicode hex": "1F7CF"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "174",
  "Dingbat hex": "AE",
  "Unicode dec": "128979",
  "Unicode hex": "1F7D3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "175",
  "Dingbat hex": "AF",
  "Unicode dec": "128977",
  "Unicode hex": "1F7D1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "176",
  "Dingbat hex": "B0",
  "Unicode dec": "11216",
  "Unicode hex": "2BD0"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "177",
  "Dingbat hex": "B1",
  "Unicode dec": "8982",
  "Unicode hex": "2316"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "178",
  "Dingbat hex": "B2",
  "Unicode dec": "11214",
  "Unicode hex": "2BCE"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "179",
  "Dingbat hex": "B3",
  "Unicode dec": "11215",
  "Unicode hex": "2BCF"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "180",
  "Dingbat hex": "B4",
  "Unicode dec": "11217",
  "Unicode hex": "2BD1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "181",
  "Dingbat hex": "B5",
  "Unicode dec": "10026",
  "Unicode hex": "272A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "182",
  "Dingbat hex": "B6",
  "Unicode dec": "10032",
  "Unicode hex": "2730"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "183",
  "Dingbat hex": "B7",
  "Unicode dec": "128336",
  "Unicode hex": "1F550"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "184",
  "Dingbat hex": "B8",
  "Unicode dec": "128337",
  "Unicode hex": "1F551"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "185",
  "Dingbat hex": "B9",
  "Unicode dec": "128338",
  "Unicode hex": "1F552"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "186",
  "Dingbat hex": "BA",
  "Unicode dec": "128339",
  "Unicode hex": "1F553"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "187",
  "Dingbat hex": "BB",
  "Unicode dec": "128340",
  "Unicode hex": "1F554"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "188",
  "Dingbat hex": "BC",
  "Unicode dec": "128341",
  "Unicode hex": "1F555"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "189",
  "Dingbat hex": "BD",
  "Unicode dec": "128342",
  "Unicode hex": "1F556"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "190",
  "Dingbat hex": "BE",
  "Unicode dec": "128343",
  "Unicode hex": "1F557"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "191",
  "Dingbat hex": "BF",
  "Unicode dec": "128344",
  "Unicode hex": "1F558"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "192",
  "Dingbat hex": "C0",
  "Unicode dec": "128345",
  "Unicode hex": "1F559"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "193",
  "Dingbat hex": "C1",
  "Unicode dec": "128346",
  "Unicode hex": "1F55A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "194",
  "Dingbat hex": "C2",
  "Unicode dec": "128347",
  "Unicode hex": "1F55B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "195",
  "Dingbat hex": "C3",
  "Unicode dec": "11184",
  "Unicode hex": "2BB0"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "196",
  "Dingbat hex": "C4",
  "Unicode dec": "11185",
  "Unicode hex": "2BB1"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "197",
  "Dingbat hex": "C5",
  "Unicode dec": "11186",
  "Unicode hex": "2BB2"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "198",
  "Dingbat hex": "C6",
  "Unicode dec": "11187",
  "Unicode hex": "2BB3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "199",
  "Dingbat hex": "C7",
  "Unicode dec": "11188",
  "Unicode hex": "2BB4"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "200",
  "Dingbat hex": "C8",
  "Unicode dec": "11189",
  "Unicode hex": "2BB5"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "201",
  "Dingbat hex": "C9",
  "Unicode dec": "11190",
  "Unicode hex": "2BB6"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "202",
  "Dingbat hex": "CA",
  "Unicode dec": "11191",
  "Unicode hex": "2BB7"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "203",
  "Dingbat hex": "CB",
  "Unicode dec": "128618",
  "Unicode hex": "1F66A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "204",
  "Dingbat hex": "CC",
  "Unicode dec": "128619",
  "Unicode hex": "1F66B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "205",
  "Dingbat hex": "CD",
  "Unicode dec": "128597",
  "Unicode hex": "1F655"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "206",
  "Dingbat hex": "CE",
  "Unicode dec": "128596",
  "Unicode hex": "1F654"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "207",
  "Dingbat hex": "CF",
  "Unicode dec": "128599",
  "Unicode hex": "1F657"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "208",
  "Dingbat hex": "D0",
  "Unicode dec": "128598",
  "Unicode hex": "1F656"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "209",
  "Dingbat hex": "D1",
  "Unicode dec": "128592",
  "Unicode hex": "1F650"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "210",
  "Dingbat hex": "D2",
  "Unicode dec": "128593",
  "Unicode hex": "1F651"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "211",
  "Dingbat hex": "D3",
  "Unicode dec": "128594",
  "Unicode hex": "1F652"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "212",
  "Dingbat hex": "D4",
  "Unicode dec": "128595",
  "Unicode hex": "1F653"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "213",
  "Dingbat hex": "D5",
  "Unicode dec": "9003",
  "Unicode hex": "232B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "214",
  "Dingbat hex": "D6",
  "Unicode dec": "8998",
  "Unicode hex": "2326"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "215",
  "Dingbat hex": "D7",
  "Unicode dec": "11160",
  "Unicode hex": "2B98"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "216",
  "Dingbat hex": "D8",
  "Unicode dec": "11162",
  "Unicode hex": "2B9A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "217",
  "Dingbat hex": "D9",
  "Unicode dec": "11161",
  "Unicode hex": "2B99"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "218",
  "Dingbat hex": "DA",
  "Unicode dec": "11163",
  "Unicode hex": "2B9B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "219",
  "Dingbat hex": "DB",
  "Unicode dec": "11144",
  "Unicode hex": "2B88"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "220",
  "Dingbat hex": "DC",
  "Unicode dec": "11146",
  "Unicode hex": "2B8A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "221",
  "Dingbat hex": "DD",
  "Unicode dec": "11145",
  "Unicode hex": "2B89"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "222",
  "Dingbat hex": "DE",
  "Unicode dec": "11147",
  "Unicode hex": "2B8B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "223",
  "Dingbat hex": "DF",
  "Unicode dec": "129128",
  "Unicode hex": "1F868"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "224",
  "Dingbat hex": "E0",
  "Unicode dec": "129130",
  "Unicode hex": "1F86A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "225",
  "Dingbat hex": "E1",
  "Unicode dec": "129129",
  "Unicode hex": "1F869"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "226",
  "Dingbat hex": "E2",
  "Unicode dec": "129131",
  "Unicode hex": "1F86B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "227",
  "Dingbat hex": "E3",
  "Unicode dec": "129132",
  "Unicode hex": "1F86C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "228",
  "Dingbat hex": "E4",
  "Unicode dec": "129133",
  "Unicode hex": "1F86D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "229",
  "Dingbat hex": "E5",
  "Unicode dec": "129135",
  "Unicode hex": "1F86F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "230",
  "Dingbat hex": "E6",
  "Unicode dec": "129134",
  "Unicode hex": "1F86E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "231",
  "Dingbat hex": "E7",
  "Unicode dec": "129144",
  "Unicode hex": "1F878"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "232",
  "Dingbat hex": "E8",
  "Unicode dec": "129146",
  "Unicode hex": "1F87A"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "233",
  "Dingbat hex": "E9",
  "Unicode dec": "129145",
  "Unicode hex": "1F879"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "234",
  "Dingbat hex": "EA",
  "Unicode dec": "129147",
  "Unicode hex": "1F87B"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "235",
  "Dingbat hex": "EB",
  "Unicode dec": "129148",
  "Unicode hex": "1F87C"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "236",
  "Dingbat hex": "EC",
  "Unicode dec": "129149",
  "Unicode hex": "1F87D"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "237",
  "Dingbat hex": "ED",
  "Unicode dec": "129151",
  "Unicode hex": "1F87F"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "238",
  "Dingbat hex": "EE",
  "Unicode dec": "129150",
  "Unicode hex": "1F87E"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "239",
  "Dingbat hex": "EF",
  "Unicode dec": "8678",
  "Unicode hex": "21E6"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "240",
  "Dingbat hex": "F0",
  "Unicode dec": "8680",
  "Unicode hex": "21E8"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "241",
  "Dingbat hex": "F1",
  "Unicode dec": "8679",
  "Unicode hex": "21E7"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "242",
  "Dingbat hex": "F2",
  "Unicode dec": "8681",
  "Unicode hex": "21E9"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "243",
  "Dingbat hex": "F3",
  "Unicode dec": "11012",
  "Unicode hex": "2B04"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "244",
  "Dingbat hex": "F4",
  "Unicode dec": "8691",
  "Unicode hex": "21F3"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "245",
  "Dingbat hex": "F5",
  "Unicode dec": "11009",
  "Unicode hex": "2B01"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "246",
  "Dingbat hex": "F6",
  "Unicode dec": "11008",
  "Unicode hex": "2B00"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "247",
  "Dingbat hex": "F7",
  "Unicode dec": "11011",
  "Unicode hex": "2B03"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "248",
  "Dingbat hex": "F8",
  "Unicode dec": "11010",
  "Unicode hex": "2B02"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "249",
  "Dingbat hex": "F9",
  "Unicode dec": "129196",
  "Unicode hex": "1F8AC"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "250",
  "Dingbat hex": "FA",
  "Unicode dec": "129197",
  "Unicode hex": "1F8AD"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "251",
  "Dingbat hex": "FB",
  "Unicode dec": "128502",
  "Unicode hex": "1F5F6"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "252",
  "Dingbat hex": "FC",
  "Unicode dec": "10003",
  "Unicode hex": "2713"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "253",
  "Dingbat hex": "FD",
  "Unicode dec": "128503",
  "Unicode hex": "1F5F7"
}, {
  "Typeface name": "Wingdings",
  "Dingbat dec": "254",
  "Dingbat hex": "FE",
  "Unicode dec": "128505",
  "Unicode hex": "1F5F9"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "32",
  "Dingbat hex": "20",
  "Unicode dec": "32",
  "Unicode hex": "20"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "33",
  "Dingbat hex": "21",
  "Unicode dec": "128394",
  "Unicode hex": "1F58A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "34",
  "Dingbat hex": "22",
  "Unicode dec": "128395",
  "Unicode hex": "1F58B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "35",
  "Dingbat hex": "23",
  "Unicode dec": "128396",
  "Unicode hex": "1F58C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "36",
  "Dingbat hex": "24",
  "Unicode dec": "128397",
  "Unicode hex": "1F58D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "37",
  "Dingbat hex": "25",
  "Unicode dec": "9988",
  "Unicode hex": "2704"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "38",
  "Dingbat hex": "26",
  "Unicode dec": "9984",
  "Unicode hex": "2700"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "39",
  "Dingbat hex": "27",
  "Unicode dec": "128382",
  "Unicode hex": "1F57E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "40",
  "Dingbat hex": "28",
  "Unicode dec": "128381",
  "Unicode hex": "1F57D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "41",
  "Dingbat hex": "29",
  "Unicode dec": "128453",
  "Unicode hex": "1F5C5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "42",
  "Dingbat hex": "2A",
  "Unicode dec": "128454",
  "Unicode hex": "1F5C6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "43",
  "Dingbat hex": "2B",
  "Unicode dec": "128455",
  "Unicode hex": "1F5C7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "44",
  "Dingbat hex": "2C",
  "Unicode dec": "128456",
  "Unicode hex": "1F5C8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "45",
  "Dingbat hex": "2D",
  "Unicode dec": "128457",
  "Unicode hex": "1F5C9"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "46",
  "Dingbat hex": "2E",
  "Unicode dec": "128458",
  "Unicode hex": "1F5CA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "47",
  "Dingbat hex": "2F",
  "Unicode dec": "128459",
  "Unicode hex": "1F5CB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "48",
  "Dingbat hex": "30",
  "Unicode dec": "128460",
  "Unicode hex": "1F5CC"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "49",
  "Dingbat hex": "31",
  "Unicode dec": "128461",
  "Unicode hex": "1F5CD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "50",
  "Dingbat hex": "32",
  "Unicode dec": "128203",
  "Unicode hex": "1F4CB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "51",
  "Dingbat hex": "33",
  "Unicode dec": "128465",
  "Unicode hex": "1F5D1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "52",
  "Dingbat hex": "34",
  "Unicode dec": "128468",
  "Unicode hex": "1F5D4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "53",
  "Dingbat hex": "35",
  "Unicode dec": "128437",
  "Unicode hex": "1F5B5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "54",
  "Dingbat hex": "36",
  "Unicode dec": "128438",
  "Unicode hex": "1F5B6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "55",
  "Dingbat hex": "37",
  "Unicode dec": "128439",
  "Unicode hex": "1F5B7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "56",
  "Dingbat hex": "38",
  "Unicode dec": "128440",
  "Unicode hex": "1F5B8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "57",
  "Dingbat hex": "39",
  "Unicode dec": "128429",
  "Unicode hex": "1F5AD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "58",
  "Dingbat hex": "3A",
  "Unicode dec": "128431",
  "Unicode hex": "1F5AF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "59",
  "Dingbat hex": "3B",
  "Unicode dec": "128433",
  "Unicode hex": "1F5B1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "60",
  "Dingbat hex": "3C",
  "Unicode dec": "128402",
  "Unicode hex": "1F592"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "61",
  "Dingbat hex": "3D",
  "Unicode dec": "128403",
  "Unicode hex": "1F593"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "62",
  "Dingbat hex": "3E",
  "Unicode dec": "128408",
  "Unicode hex": "1F598"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "63",
  "Dingbat hex": "3F",
  "Unicode dec": "128409",
  "Unicode hex": "1F599"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "64",
  "Dingbat hex": "40",
  "Unicode dec": "128410",
  "Unicode hex": "1F59A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "65",
  "Dingbat hex": "41",
  "Unicode dec": "128411",
  "Unicode hex": "1F59B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "66",
  "Dingbat hex": "42",
  "Unicode dec": "128072",
  "Unicode hex": "1F448"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "67",
  "Dingbat hex": "43",
  "Unicode dec": "128073",
  "Unicode hex": "1F449"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "68",
  "Dingbat hex": "44",
  "Unicode dec": "128412",
  "Unicode hex": "1F59C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "69",
  "Dingbat hex": "45",
  "Unicode dec": "128413",
  "Unicode hex": "1F59D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "70",
  "Dingbat hex": "46",
  "Unicode dec": "128414",
  "Unicode hex": "1F59E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "71",
  "Dingbat hex": "47",
  "Unicode dec": "128415",
  "Unicode hex": "1F59F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "72",
  "Dingbat hex": "48",
  "Unicode dec": "128416",
  "Unicode hex": "1F5A0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "73",
  "Dingbat hex": "49",
  "Unicode dec": "128417",
  "Unicode hex": "1F5A1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "74",
  "Dingbat hex": "4A",
  "Unicode dec": "128070",
  "Unicode hex": "1F446"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "75",
  "Dingbat hex": "4B",
  "Unicode dec": "128071",
  "Unicode hex": "1F447"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "76",
  "Dingbat hex": "4C",
  "Unicode dec": "128418",
  "Unicode hex": "1F5A2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "77",
  "Dingbat hex": "4D",
  "Unicode dec": "128419",
  "Unicode hex": "1F5A3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "78",
  "Dingbat hex": "4E",
  "Unicode dec": "128401",
  "Unicode hex": "1F591"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "79",
  "Dingbat hex": "4F",
  "Unicode dec": "128500",
  "Unicode hex": "1F5F4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "80",
  "Dingbat hex": "50",
  "Unicode dec": "128504",
  "Unicode hex": "1F5F8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "81",
  "Dingbat hex": "51",
  "Unicode dec": "128501",
  "Unicode hex": "1F5F5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "82",
  "Dingbat hex": "52",
  "Unicode dec": "9745",
  "Unicode hex": "2611"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "83",
  "Dingbat hex": "53",
  "Unicode dec": "11197",
  "Unicode hex": "2BBD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "84",
  "Dingbat hex": "54",
  "Unicode dec": "9746",
  "Unicode hex": "2612"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "85",
  "Dingbat hex": "55",
  "Unicode dec": "11198",
  "Unicode hex": "2BBE"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "86",
  "Dingbat hex": "56",
  "Unicode dec": "11199",
  "Unicode hex": "2BBF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "87",
  "Dingbat hex": "57",
  "Unicode dec": "128711",
  "Unicode hex": "1F6C7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "88",
  "Dingbat hex": "58",
  "Unicode dec": "10680",
  "Unicode hex": "29B8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "89",
  "Dingbat hex": "59",
  "Unicode dec": "128625",
  "Unicode hex": "1F671"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "90",
  "Dingbat hex": "5A",
  "Unicode dec": "128628",
  "Unicode hex": "1F674"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "91",
  "Dingbat hex": "5B",
  "Unicode dec": "128626",
  "Unicode hex": "1F672"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "92",
  "Dingbat hex": "5C",
  "Unicode dec": "128627",
  "Unicode hex": "1F673"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "93",
  "Dingbat hex": "5D",
  "Unicode dec": "8253",
  "Unicode hex": "203D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "94",
  "Dingbat hex": "5E",
  "Unicode dec": "128633",
  "Unicode hex": "1F679"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "95",
  "Dingbat hex": "5F",
  "Unicode dec": "128634",
  "Unicode hex": "1F67A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "96",
  "Dingbat hex": "60",
  "Unicode dec": "128635",
  "Unicode hex": "1F67B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "97",
  "Dingbat hex": "61",
  "Unicode dec": "128614",
  "Unicode hex": "1F666"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "98",
  "Dingbat hex": "62",
  "Unicode dec": "128612",
  "Unicode hex": "1F664"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "99",
  "Dingbat hex": "63",
  "Unicode dec": "128613",
  "Unicode hex": "1F665"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "100",
  "Dingbat hex": "64",
  "Unicode dec": "128615",
  "Unicode hex": "1F667"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "101",
  "Dingbat hex": "65",
  "Unicode dec": "128602",
  "Unicode hex": "1F65A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "102",
  "Dingbat hex": "66",
  "Unicode dec": "128600",
  "Unicode hex": "1F658"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "103",
  "Dingbat hex": "67",
  "Unicode dec": "128601",
  "Unicode hex": "1F659"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "104",
  "Dingbat hex": "68",
  "Unicode dec": "128603",
  "Unicode hex": "1F65B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "105",
  "Dingbat hex": "69",
  "Unicode dec": "9450",
  "Unicode hex": "24EA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "106",
  "Dingbat hex": "6A",
  "Unicode dec": "9312",
  "Unicode hex": "2460"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "107",
  "Dingbat hex": "6B",
  "Unicode dec": "9313",
  "Unicode hex": "2461"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "108",
  "Dingbat hex": "6C",
  "Unicode dec": "9314",
  "Unicode hex": "2462"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "109",
  "Dingbat hex": "6D",
  "Unicode dec": "9315",
  "Unicode hex": "2463"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "110",
  "Dingbat hex": "6E",
  "Unicode dec": "9316",
  "Unicode hex": "2464"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "111",
  "Dingbat hex": "6F",
  "Unicode dec": "9317",
  "Unicode hex": "2465"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "112",
  "Dingbat hex": "70",
  "Unicode dec": "9318",
  "Unicode hex": "2466"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "113",
  "Dingbat hex": "71",
  "Unicode dec": "9319",
  "Unicode hex": "2467"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "114",
  "Dingbat hex": "72",
  "Unicode dec": "9320",
  "Unicode hex": "2468"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "115",
  "Dingbat hex": "73",
  "Unicode dec": "9321",
  "Unicode hex": "2469"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "116",
  "Dingbat hex": "74",
  "Unicode dec": "9471",
  "Unicode hex": "24FF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "117",
  "Dingbat hex": "75",
  "Unicode dec": "10102",
  "Unicode hex": "2776"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "118",
  "Dingbat hex": "76",
  "Unicode dec": "10103",
  "Unicode hex": "2777"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "119",
  "Dingbat hex": "77",
  "Unicode dec": "10104",
  "Unicode hex": "2778"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "120",
  "Dingbat hex": "78",
  "Unicode dec": "10105",
  "Unicode hex": "2779"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "121",
  "Dingbat hex": "79",
  "Unicode dec": "10106",
  "Unicode hex": "277A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "122",
  "Dingbat hex": "7A",
  "Unicode dec": "10107",
  "Unicode hex": "277B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "123",
  "Dingbat hex": "7B",
  "Unicode dec": "10108",
  "Unicode hex": "277C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "124",
  "Dingbat hex": "7C",
  "Unicode dec": "10109",
  "Unicode hex": "277D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "125",
  "Dingbat hex": "7D",
  "Unicode dec": "10110",
  "Unicode hex": "277E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "126",
  "Dingbat hex": "7E",
  "Unicode dec": "10111",
  "Unicode hex": "277F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "128",
  "Dingbat hex": "80",
  "Unicode dec": "9737",
  "Unicode hex": "2609"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "129",
  "Dingbat hex": "81",
  "Unicode dec": "127765",
  "Unicode hex": "1F315"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "130",
  "Dingbat hex": "82",
  "Unicode dec": "9789",
  "Unicode hex": "263D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "131",
  "Dingbat hex": "83",
  "Unicode dec": "9790",
  "Unicode hex": "263E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "132",
  "Dingbat hex": "84",
  "Unicode dec": "11839",
  "Unicode hex": "2E3F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "133",
  "Dingbat hex": "85",
  "Unicode dec": "10013",
  "Unicode hex": "271D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "134",
  "Dingbat hex": "86",
  "Unicode dec": "128327",
  "Unicode hex": "1F547"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "135",
  "Dingbat hex": "87",
  "Unicode dec": "128348",
  "Unicode hex": "1F55C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "136",
  "Dingbat hex": "88",
  "Unicode dec": "128349",
  "Unicode hex": "1F55D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "137",
  "Dingbat hex": "89",
  "Unicode dec": "128350",
  "Unicode hex": "1F55E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "138",
  "Dingbat hex": "8A",
  "Unicode dec": "128351",
  "Unicode hex": "1F55F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "139",
  "Dingbat hex": "8B",
  "Unicode dec": "128352",
  "Unicode hex": "1F560"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "140",
  "Dingbat hex": "8C",
  "Unicode dec": "128353",
  "Unicode hex": "1F561"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "141",
  "Dingbat hex": "8D",
  "Unicode dec": "128354",
  "Unicode hex": "1F562"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "142",
  "Dingbat hex": "8E",
  "Unicode dec": "128355",
  "Unicode hex": "1F563"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "143",
  "Dingbat hex": "8F",
  "Unicode dec": "128356",
  "Unicode hex": "1F564"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "144",
  "Dingbat hex": "90",
  "Unicode dec": "128357",
  "Unicode hex": "1F565"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "145",
  "Dingbat hex": "91",
  "Unicode dec": "128358",
  "Unicode hex": "1F566"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "146",
  "Dingbat hex": "92",
  "Unicode dec": "128359",
  "Unicode hex": "1F567"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "147",
  "Dingbat hex": "93",
  "Unicode dec": "128616",
  "Unicode hex": "1F668"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "148",
  "Dingbat hex": "94",
  "Unicode dec": "128617",
  "Unicode hex": "1F669"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "149",
  "Dingbat hex": "95",
  "Unicode dec": "8901",
  "Unicode hex": "22C5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "150",
  "Dingbat hex": "96",
  "Unicode dec": "128900",
  "Unicode hex": "1F784"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "151",
  "Dingbat hex": "97",
  "Unicode dec": "10625",
  "Unicode hex": "2981"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "152",
  "Dingbat hex": "98",
  "Unicode dec": "9679",
  "Unicode hex": "25CF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "153",
  "Dingbat hex": "99",
  "Unicode dec": "9675",
  "Unicode hex": "25CB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "154",
  "Dingbat hex": "9A",
  "Unicode dec": "128901",
  "Unicode hex": "1F785"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "155",
  "Dingbat hex": "9B",
  "Unicode dec": "128903",
  "Unicode hex": "1F787"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "156",
  "Dingbat hex": "9C",
  "Unicode dec": "128905",
  "Unicode hex": "1F789"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "157",
  "Dingbat hex": "9D",
  "Unicode dec": "8857",
  "Unicode hex": "2299"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "158",
  "Dingbat hex": "9E",
  "Unicode dec": "10687",
  "Unicode hex": "29BF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "159",
  "Dingbat hex": "9F",
  "Unicode dec": "128908",
  "Unicode hex": "1F78C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "160",
  "Dingbat hex": "A0",
  "Unicode dec": "128909",
  "Unicode hex": "1F78D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "161",
  "Dingbat hex": "A1",
  "Unicode dec": "9726",
  "Unicode hex": "25FE"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "162",
  "Dingbat hex": "A2",
  "Unicode dec": "9632",
  "Unicode hex": "25A0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "163",
  "Dingbat hex": "A3",
  "Unicode dec": "9633",
  "Unicode hex": "25A1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "164",
  "Dingbat hex": "A4",
  "Unicode dec": "128913",
  "Unicode hex": "1F791"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "165",
  "Dingbat hex": "A5",
  "Unicode dec": "128914",
  "Unicode hex": "1F792"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "166",
  "Dingbat hex": "A6",
  "Unicode dec": "128915",
  "Unicode hex": "1F793"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "167",
  "Dingbat hex": "A7",
  "Unicode dec": "128916",
  "Unicode hex": "1F794"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "168",
  "Dingbat hex": "A8",
  "Unicode dec": "9635",
  "Unicode hex": "25A3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "169",
  "Dingbat hex": "A9",
  "Unicode dec": "128917",
  "Unicode hex": "1F795"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "170",
  "Dingbat hex": "AA",
  "Unicode dec": "128918",
  "Unicode hex": "1F796"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "171",
  "Dingbat hex": "AB",
  "Unicode dec": "128919",
  "Unicode hex": "1F797"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "172",
  "Dingbat hex": "AC",
  "Unicode dec": "128920",
  "Unicode hex": "1F798"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "173",
  "Dingbat hex": "AD",
  "Unicode dec": "11049",
  "Unicode hex": "2B29"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "174",
  "Dingbat hex": "AE",
  "Unicode dec": "11045",
  "Unicode hex": "2B25"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "175",
  "Dingbat hex": "AF",
  "Unicode dec": "9671",
  "Unicode hex": "25C7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "176",
  "Dingbat hex": "B0",
  "Unicode dec": "128922",
  "Unicode hex": "1F79A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "177",
  "Dingbat hex": "B1",
  "Unicode dec": "9672",
  "Unicode hex": "25C8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "178",
  "Dingbat hex": "B2",
  "Unicode dec": "128923",
  "Unicode hex": "1F79B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "179",
  "Dingbat hex": "B3",
  "Unicode dec": "128924",
  "Unicode hex": "1F79C"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "180",
  "Dingbat hex": "B4",
  "Unicode dec": "128925",
  "Unicode hex": "1F79D"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "181",
  "Dingbat hex": "B5",
  "Unicode dec": "128926",
  "Unicode hex": "1F79E"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "182",
  "Dingbat hex": "B6",
  "Unicode dec": "11050",
  "Unicode hex": "2B2A"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "183",
  "Dingbat hex": "B7",
  "Unicode dec": "11047",
  "Unicode hex": "2B27"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "184",
  "Dingbat hex": "B8",
  "Unicode dec": "9674",
  "Unicode hex": "25CA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "185",
  "Dingbat hex": "B9",
  "Unicode dec": "128928",
  "Unicode hex": "1F7A0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "186",
  "Dingbat hex": "BA",
  "Unicode dec": "9686",
  "Unicode hex": "25D6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "187",
  "Dingbat hex": "BB",
  "Unicode dec": "9687",
  "Unicode hex": "25D7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "188",
  "Dingbat hex": "BC",
  "Unicode dec": "11210",
  "Unicode hex": "2BCA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "189",
  "Dingbat hex": "BD",
  "Unicode dec": "11211",
  "Unicode hex": "2BCB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "190",
  "Dingbat hex": "BE",
  "Unicode dec": "11200",
  "Unicode hex": "2BC0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "191",
  "Dingbat hex": "BF",
  "Unicode dec": "11201",
  "Unicode hex": "2BC1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "192",
  "Dingbat hex": "C0",
  "Unicode dec": "11039",
  "Unicode hex": "2B1F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "193",
  "Dingbat hex": "C1",
  "Unicode dec": "11202",
  "Unicode hex": "2BC2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "194",
  "Dingbat hex": "C2",
  "Unicode dec": "11043",
  "Unicode hex": "2B23"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "195",
  "Dingbat hex": "C3",
  "Unicode dec": "11042",
  "Unicode hex": "2B22"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "196",
  "Dingbat hex": "C4",
  "Unicode dec": "11203",
  "Unicode hex": "2BC3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "197",
  "Dingbat hex": "C5",
  "Unicode dec": "11204",
  "Unicode hex": "2BC4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "198",
  "Dingbat hex": "C6",
  "Unicode dec": "128929",
  "Unicode hex": "1F7A1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "199",
  "Dingbat hex": "C7",
  "Unicode dec": "128930",
  "Unicode hex": "1F7A2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "200",
  "Dingbat hex": "C8",
  "Unicode dec": "128931",
  "Unicode hex": "1F7A3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "201",
  "Dingbat hex": "C9",
  "Unicode dec": "128932",
  "Unicode hex": "1F7A4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "202",
  "Dingbat hex": "CA",
  "Unicode dec": "128933",
  "Unicode hex": "1F7A5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "203",
  "Dingbat hex": "CB",
  "Unicode dec": "128934",
  "Unicode hex": "1F7A6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "204",
  "Dingbat hex": "CC",
  "Unicode dec": "128935",
  "Unicode hex": "1F7A7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "205",
  "Dingbat hex": "CD",
  "Unicode dec": "128936",
  "Unicode hex": "1F7A8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "206",
  "Dingbat hex": "CE",
  "Unicode dec": "128937",
  "Unicode hex": "1F7A9"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "207",
  "Dingbat hex": "CF",
  "Unicode dec": "128938",
  "Unicode hex": "1F7AA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "208",
  "Dingbat hex": "D0",
  "Unicode dec": "128939",
  "Unicode hex": "1F7AB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "209",
  "Dingbat hex": "D1",
  "Unicode dec": "128940",
  "Unicode hex": "1F7AC"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "210",
  "Dingbat hex": "D2",
  "Unicode dec": "128941",
  "Unicode hex": "1F7AD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "211",
  "Dingbat hex": "D3",
  "Unicode dec": "128942",
  "Unicode hex": "1F7AE"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "212",
  "Dingbat hex": "D4",
  "Unicode dec": "128943",
  "Unicode hex": "1F7AF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "213",
  "Dingbat hex": "D5",
  "Unicode dec": "128944",
  "Unicode hex": "1F7B0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "214",
  "Dingbat hex": "D6",
  "Unicode dec": "128945",
  "Unicode hex": "1F7B1"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "215",
  "Dingbat hex": "D7",
  "Unicode dec": "128946",
  "Unicode hex": "1F7B2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "216",
  "Dingbat hex": "D8",
  "Unicode dec": "128947",
  "Unicode hex": "1F7B3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "217",
  "Dingbat hex": "D9",
  "Unicode dec": "128948",
  "Unicode hex": "1F7B4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "218",
  "Dingbat hex": "DA",
  "Unicode dec": "128949",
  "Unicode hex": "1F7B5"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "219",
  "Dingbat hex": "DB",
  "Unicode dec": "128950",
  "Unicode hex": "1F7B6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "220",
  "Dingbat hex": "DC",
  "Unicode dec": "128951",
  "Unicode hex": "1F7B7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "221",
  "Dingbat hex": "DD",
  "Unicode dec": "128952",
  "Unicode hex": "1F7B8"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "222",
  "Dingbat hex": "DE",
  "Unicode dec": "128953",
  "Unicode hex": "1F7B9"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "223",
  "Dingbat hex": "DF",
  "Unicode dec": "128954",
  "Unicode hex": "1F7BA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "224",
  "Dingbat hex": "E0",
  "Unicode dec": "128955",
  "Unicode hex": "1F7BB"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "225",
  "Dingbat hex": "E1",
  "Unicode dec": "128956",
  "Unicode hex": "1F7BC"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "226",
  "Dingbat hex": "E2",
  "Unicode dec": "128957",
  "Unicode hex": "1F7BD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "227",
  "Dingbat hex": "E3",
  "Unicode dec": "128958",
  "Unicode hex": "1F7BE"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "228",
  "Dingbat hex": "E4",
  "Unicode dec": "128959",
  "Unicode hex": "1F7BF"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "229",
  "Dingbat hex": "E5",
  "Unicode dec": "128960",
  "Unicode hex": "1F7C0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "230",
  "Dingbat hex": "E6",
  "Unicode dec": "128962",
  "Unicode hex": "1F7C2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "231",
  "Dingbat hex": "E7",
  "Unicode dec": "128964",
  "Unicode hex": "1F7C4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "232",
  "Dingbat hex": "E8",
  "Unicode dec": "128966",
  "Unicode hex": "1F7C6"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "233",
  "Dingbat hex": "E9",
  "Unicode dec": "128969",
  "Unicode hex": "1F7C9"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "234",
  "Dingbat hex": "EA",
  "Unicode dec": "128970",
  "Unicode hex": "1F7CA"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "235",
  "Dingbat hex": "EB",
  "Unicode dec": "10038",
  "Unicode hex": "2736"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "236",
  "Dingbat hex": "EC",
  "Unicode dec": "128972",
  "Unicode hex": "1F7CC"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "237",
  "Dingbat hex": "ED",
  "Unicode dec": "128974",
  "Unicode hex": "1F7CE"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "238",
  "Dingbat hex": "EE",
  "Unicode dec": "128976",
  "Unicode hex": "1F7D0"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "239",
  "Dingbat hex": "EF",
  "Unicode dec": "128978",
  "Unicode hex": "1F7D2"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "240",
  "Dingbat hex": "F0",
  "Unicode dec": "10041",
  "Unicode hex": "2739"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "241",
  "Dingbat hex": "F1",
  "Unicode dec": "128963",
  "Unicode hex": "1F7C3"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "242",
  "Dingbat hex": "F2",
  "Unicode dec": "128967",
  "Unicode hex": "1F7C7"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "243",
  "Dingbat hex": "F3",
  "Unicode dec": "10031",
  "Unicode hex": "272F"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "244",
  "Dingbat hex": "F4",
  "Unicode dec": "128973",
  "Unicode hex": "1F7CD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "245",
  "Dingbat hex": "F5",
  "Unicode dec": "128980",
  "Unicode hex": "1F7D4"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "246",
  "Dingbat hex": "F6",
  "Unicode dec": "11212",
  "Unicode hex": "2BCC"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "247",
  "Dingbat hex": "F7",
  "Unicode dec": "11213",
  "Unicode hex": "2BCD"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "248",
  "Dingbat hex": "F8",
  "Unicode dec": "8251",
  "Unicode hex": "203B"
}, {
  "Typeface name": "Wingdings 2",
  "Dingbat dec": "249",
  "Dingbat hex": "F9",
  "Unicode dec": "8258",
  "Unicode hex": "2042"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "32",
  "Dingbat hex": "20",
  "Unicode dec": "32",
  "Unicode hex": "20"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "33",
  "Dingbat hex": "21",
  "Unicode dec": "11104",
  "Unicode hex": "2B60"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "34",
  "Dingbat hex": "22",
  "Unicode dec": "11106",
  "Unicode hex": "2B62"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "35",
  "Dingbat hex": "23",
  "Unicode dec": "11105",
  "Unicode hex": "2B61"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "36",
  "Dingbat hex": "24",
  "Unicode dec": "11107",
  "Unicode hex": "2B63"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "37",
  "Dingbat hex": "25",
  "Unicode dec": "11110",
  "Unicode hex": "2B66"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "38",
  "Dingbat hex": "26",
  "Unicode dec": "11111",
  "Unicode hex": "2B67"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "39",
  "Dingbat hex": "27",
  "Unicode dec": "11113",
  "Unicode hex": "2B69"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "40",
  "Dingbat hex": "28",
  "Unicode dec": "11112",
  "Unicode hex": "2B68"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "41",
  "Dingbat hex": "29",
  "Unicode dec": "11120",
  "Unicode hex": "2B70"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "42",
  "Dingbat hex": "2A",
  "Unicode dec": "11122",
  "Unicode hex": "2B72"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "43",
  "Dingbat hex": "2B",
  "Unicode dec": "11121",
  "Unicode hex": "2B71"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "44",
  "Dingbat hex": "2C",
  "Unicode dec": "11123",
  "Unicode hex": "2B73"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "45",
  "Dingbat hex": "2D",
  "Unicode dec": "11126",
  "Unicode hex": "2B76"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "46",
  "Dingbat hex": "2E",
  "Unicode dec": "11128",
  "Unicode hex": "2B78"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "47",
  "Dingbat hex": "2F",
  "Unicode dec": "11131",
  "Unicode hex": "2B7B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "48",
  "Dingbat hex": "30",
  "Unicode dec": "11133",
  "Unicode hex": "2B7D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "49",
  "Dingbat hex": "31",
  "Unicode dec": "11108",
  "Unicode hex": "2B64"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "50",
  "Dingbat hex": "32",
  "Unicode dec": "11109",
  "Unicode hex": "2B65"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "51",
  "Dingbat hex": "33",
  "Unicode dec": "11114",
  "Unicode hex": "2B6A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "52",
  "Dingbat hex": "34",
  "Unicode dec": "11116",
  "Unicode hex": "2B6C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "53",
  "Dingbat hex": "35",
  "Unicode dec": "11115",
  "Unicode hex": "2B6B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "54",
  "Dingbat hex": "36",
  "Unicode dec": "11117",
  "Unicode hex": "2B6D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "55",
  "Dingbat hex": "37",
  "Unicode dec": "11085",
  "Unicode hex": "2B4D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "56",
  "Dingbat hex": "38",
  "Unicode dec": "11168",
  "Unicode hex": "2BA0"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "57",
  "Dingbat hex": "39",
  "Unicode dec": "11169",
  "Unicode hex": "2BA1"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "58",
  "Dingbat hex": "3A",
  "Unicode dec": "11170",
  "Unicode hex": "2BA2"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "59",
  "Dingbat hex": "3B",
  "Unicode dec": "11171",
  "Unicode hex": "2BA3"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "60",
  "Dingbat hex": "3C",
  "Unicode dec": "11172",
  "Unicode hex": "2BA4"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "61",
  "Dingbat hex": "3D",
  "Unicode dec": "11173",
  "Unicode hex": "2BA5"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "62",
  "Dingbat hex": "3E",
  "Unicode dec": "11174",
  "Unicode hex": "2BA6"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "63",
  "Dingbat hex": "3F",
  "Unicode dec": "11175",
  "Unicode hex": "2BA7"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "64",
  "Dingbat hex": "40",
  "Unicode dec": "11152",
  "Unicode hex": "2B90"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "65",
  "Dingbat hex": "41",
  "Unicode dec": "11153",
  "Unicode hex": "2B91"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "66",
  "Dingbat hex": "42",
  "Unicode dec": "11154",
  "Unicode hex": "2B92"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "67",
  "Dingbat hex": "43",
  "Unicode dec": "11155",
  "Unicode hex": "2B93"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "68",
  "Dingbat hex": "44",
  "Unicode dec": "11136",
  "Unicode hex": "2B80"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "69",
  "Dingbat hex": "45",
  "Unicode dec": "11139",
  "Unicode hex": "2B83"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "70",
  "Dingbat hex": "46",
  "Unicode dec": "11134",
  "Unicode hex": "2B7E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "71",
  "Dingbat hex": "47",
  "Unicode dec": "11135",
  "Unicode hex": "2B7F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "72",
  "Dingbat hex": "48",
  "Unicode dec": "11140",
  "Unicode hex": "2B84"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "73",
  "Dingbat hex": "49",
  "Unicode dec": "11142",
  "Unicode hex": "2B86"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "74",
  "Dingbat hex": "4A",
  "Unicode dec": "11141",
  "Unicode hex": "2B85"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "75",
  "Dingbat hex": "4B",
  "Unicode dec": "11143",
  "Unicode hex": "2B87"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "76",
  "Dingbat hex": "4C",
  "Unicode dec": "11151",
  "Unicode hex": "2B8F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "77",
  "Dingbat hex": "4D",
  "Unicode dec": "11149",
  "Unicode hex": "2B8D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "78",
  "Dingbat hex": "4E",
  "Unicode dec": "11150",
  "Unicode hex": "2B8E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "79",
  "Dingbat hex": "4F",
  "Unicode dec": "11148",
  "Unicode hex": "2B8C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "80",
  "Dingbat hex": "50",
  "Unicode dec": "11118",
  "Unicode hex": "2B6E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "81",
  "Dingbat hex": "51",
  "Unicode dec": "11119",
  "Unicode hex": "2B6F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "82",
  "Dingbat hex": "52",
  "Unicode dec": "9099",
  "Unicode hex": "238B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "83",
  "Dingbat hex": "53",
  "Unicode dec": "8996",
  "Unicode hex": "2324"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "84",
  "Dingbat hex": "54",
  "Unicode dec": "8963",
  "Unicode hex": "2303"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "85",
  "Dingbat hex": "55",
  "Unicode dec": "8997",
  "Unicode hex": "2325"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "86",
  "Dingbat hex": "56",
  "Unicode dec": "9251",
  "Unicode hex": "2423"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "87",
  "Dingbat hex": "57",
  "Unicode dec": "9085",
  "Unicode hex": "237D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "88",
  "Dingbat hex": "58",
  "Unicode dec": "8682",
  "Unicode hex": "21EA"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "89",
  "Dingbat hex": "59",
  "Unicode dec": "11192",
  "Unicode hex": "2BB8"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "90",
  "Dingbat hex": "5A",
  "Unicode dec": "129184",
  "Unicode hex": "1F8A0"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "91",
  "Dingbat hex": "5B",
  "Unicode dec": "129185",
  "Unicode hex": "1F8A1"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "92",
  "Dingbat hex": "5C",
  "Unicode dec": "129186",
  "Unicode hex": "1F8A2"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "93",
  "Dingbat hex": "5D",
  "Unicode dec": "129187",
  "Unicode hex": "1F8A3"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "94",
  "Dingbat hex": "5E",
  "Unicode dec": "129188",
  "Unicode hex": "1F8A4"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "95",
  "Dingbat hex": "5F",
  "Unicode dec": "129189",
  "Unicode hex": "1F8A5"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "96",
  "Dingbat hex": "60",
  "Unicode dec": "129190",
  "Unicode hex": "1F8A6"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "97",
  "Dingbat hex": "61",
  "Unicode dec": "129191",
  "Unicode hex": "1F8A7"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "98",
  "Dingbat hex": "62",
  "Unicode dec": "129192",
  "Unicode hex": "1F8A8"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "99",
  "Dingbat hex": "63",
  "Unicode dec": "129193",
  "Unicode hex": "1F8A9"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "100",
  "Dingbat hex": "64",
  "Unicode dec": "129194",
  "Unicode hex": "1F8AA"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "101",
  "Dingbat hex": "65",
  "Unicode dec": "129195",
  "Unicode hex": "1F8AB"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "102",
  "Dingbat hex": "66",
  "Unicode dec": "129104",
  "Unicode hex": "1F850"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "103",
  "Dingbat hex": "67",
  "Unicode dec": "129106",
  "Unicode hex": "1F852"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "104",
  "Dingbat hex": "68",
  "Unicode dec": "129105",
  "Unicode hex": "1F851"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "105",
  "Dingbat hex": "69",
  "Unicode dec": "129107",
  "Unicode hex": "1F853"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "106",
  "Dingbat hex": "6A",
  "Unicode dec": "129108",
  "Unicode hex": "1F854"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "107",
  "Dingbat hex": "6B",
  "Unicode dec": "129109",
  "Unicode hex": "1F855"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "108",
  "Dingbat hex": "6C",
  "Unicode dec": "129111",
  "Unicode hex": "1F857"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "109",
  "Dingbat hex": "6D",
  "Unicode dec": "129110",
  "Unicode hex": "1F856"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "110",
  "Dingbat hex": "6E",
  "Unicode dec": "129112",
  "Unicode hex": "1F858"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "111",
  "Dingbat hex": "6F",
  "Unicode dec": "129113",
  "Unicode hex": "1F859"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "112",
  "Dingbat hex": "70",
  "Unicode dec": "9650",
  "Unicode hex": "25B2"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "113",
  "Dingbat hex": "71",
  "Unicode dec": "9660",
  "Unicode hex": "25BC"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "114",
  "Dingbat hex": "72",
  "Unicode dec": "9651",
  "Unicode hex": "25B3"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "115",
  "Dingbat hex": "73",
  "Unicode dec": "9661",
  "Unicode hex": "25BD"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "116",
  "Dingbat hex": "74",
  "Unicode dec": "9664",
  "Unicode hex": "25C0"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "117",
  "Dingbat hex": "75",
  "Unicode dec": "9654",
  "Unicode hex": "25B6"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "118",
  "Dingbat hex": "76",
  "Unicode dec": "9665",
  "Unicode hex": "25C1"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "119",
  "Dingbat hex": "77",
  "Unicode dec": "9655",
  "Unicode hex": "25B7"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "120",
  "Dingbat hex": "78",
  "Unicode dec": "9699",
  "Unicode hex": "25E3"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "121",
  "Dingbat hex": "79",
  "Unicode dec": "9698",
  "Unicode hex": "25E2"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "122",
  "Dingbat hex": "7A",
  "Unicode dec": "9700",
  "Unicode hex": "25E4"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "123",
  "Dingbat hex": "7B",
  "Unicode dec": "9701",
  "Unicode hex": "25E5"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "124",
  "Dingbat hex": "7C",
  "Unicode dec": "128896",
  "Unicode hex": "1F780"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "125",
  "Dingbat hex": "7D",
  "Unicode dec": "128898",
  "Unicode hex": "1F782"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "126",
  "Dingbat hex": "7E",
  "Unicode dec": "128897",
  "Unicode hex": "1F781"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "128",
  "Dingbat hex": "80",
  "Unicode dec": "128899",
  "Unicode hex": "1F783"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "129",
  "Dingbat hex": "81",
  "Unicode dec": "11205",
  "Unicode hex": "2BC5"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "130",
  "Dingbat hex": "82",
  "Unicode dec": "11206",
  "Unicode hex": "2BC6"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "131",
  "Dingbat hex": "83",
  "Unicode dec": "11207",
  "Unicode hex": "2BC7"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "132",
  "Dingbat hex": "84",
  "Unicode dec": "11208",
  "Unicode hex": "2BC8"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "133",
  "Dingbat hex": "85",
  "Unicode dec": "11164",
  "Unicode hex": "2B9C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "134",
  "Dingbat hex": "86",
  "Unicode dec": "11166",
  "Unicode hex": "2B9E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "135",
  "Dingbat hex": "87",
  "Unicode dec": "11165",
  "Unicode hex": "2B9D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "136",
  "Dingbat hex": "88",
  "Unicode dec": "11167",
  "Unicode hex": "2B9F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "137",
  "Dingbat hex": "89",
  "Unicode dec": "129040",
  "Unicode hex": "1F810"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "138",
  "Dingbat hex": "8A",
  "Unicode dec": "129042",
  "Unicode hex": "1F812"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "139",
  "Dingbat hex": "8B",
  "Unicode dec": "129041",
  "Unicode hex": "1F811"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "140",
  "Dingbat hex": "8C",
  "Unicode dec": "129043",
  "Unicode hex": "1F813"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "141",
  "Dingbat hex": "8D",
  "Unicode dec": "129044",
  "Unicode hex": "1F814"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "142",
  "Dingbat hex": "8E",
  "Unicode dec": "129046",
  "Unicode hex": "1F816"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "143",
  "Dingbat hex": "8F",
  "Unicode dec": "129045",
  "Unicode hex": "1F815"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "144",
  "Dingbat hex": "90",
  "Unicode dec": "129047",
  "Unicode hex": "1F817"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "145",
  "Dingbat hex": "91",
  "Unicode dec": "129048",
  "Unicode hex": "1F818"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "146",
  "Dingbat hex": "92",
  "Unicode dec": "129050",
  "Unicode hex": "1F81A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "147",
  "Dingbat hex": "93",
  "Unicode dec": "129049",
  "Unicode hex": "1F819"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "148",
  "Dingbat hex": "94",
  "Unicode dec": "129051",
  "Unicode hex": "1F81B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "149",
  "Dingbat hex": "95",
  "Unicode dec": "129052",
  "Unicode hex": "1F81C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "150",
  "Dingbat hex": "96",
  "Unicode dec": "129054",
  "Unicode hex": "1F81E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "151",
  "Dingbat hex": "97",
  "Unicode dec": "129053",
  "Unicode hex": "1F81D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "152",
  "Dingbat hex": "98",
  "Unicode dec": "129055",
  "Unicode hex": "1F81F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "153",
  "Dingbat hex": "99",
  "Unicode dec": "129024",
  "Unicode hex": "1F800"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "154",
  "Dingbat hex": "9A",
  "Unicode dec": "129026",
  "Unicode hex": "1F802"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "155",
  "Dingbat hex": "9B",
  "Unicode dec": "129025",
  "Unicode hex": "1F801"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "156",
  "Dingbat hex": "9C",
  "Unicode dec": "129027",
  "Unicode hex": "1F803"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "157",
  "Dingbat hex": "9D",
  "Unicode dec": "129028",
  "Unicode hex": "1F804"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "158",
  "Dingbat hex": "9E",
  "Unicode dec": "129030",
  "Unicode hex": "1F806"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "159",
  "Dingbat hex": "9F",
  "Unicode dec": "129029",
  "Unicode hex": "1F805"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "160",
  "Dingbat hex": "A0",
  "Unicode dec": "129031",
  "Unicode hex": "1F807"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "161",
  "Dingbat hex": "A1",
  "Unicode dec": "129032",
  "Unicode hex": "1F808"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "162",
  "Dingbat hex": "A2",
  "Unicode dec": "129034",
  "Unicode hex": "1F80A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "163",
  "Dingbat hex": "A3",
  "Unicode dec": "129033",
  "Unicode hex": "1F809"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "164",
  "Dingbat hex": "A4",
  "Unicode dec": "129035",
  "Unicode hex": "1F80B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "165",
  "Dingbat hex": "A5",
  "Unicode dec": "129056",
  "Unicode hex": "1F820"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "166",
  "Dingbat hex": "A6",
  "Unicode dec": "129058",
  "Unicode hex": "1F822"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "167",
  "Dingbat hex": "A7",
  "Unicode dec": "129060",
  "Unicode hex": "1F824"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "168",
  "Dingbat hex": "A8",
  "Unicode dec": "129062",
  "Unicode hex": "1F826"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "169",
  "Dingbat hex": "A9",
  "Unicode dec": "129064",
  "Unicode hex": "1F828"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "170",
  "Dingbat hex": "AA",
  "Unicode dec": "129066",
  "Unicode hex": "1F82A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "171",
  "Dingbat hex": "AB",
  "Unicode dec": "129068",
  "Unicode hex": "1F82C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "172",
  "Dingbat hex": "AC",
  "Unicode dec": "129180",
  "Unicode hex": "1F89C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "173",
  "Dingbat hex": "AD",
  "Unicode dec": "129181",
  "Unicode hex": "1F89D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "174",
  "Dingbat hex": "AE",
  "Unicode dec": "129182",
  "Unicode hex": "1F89E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "175",
  "Dingbat hex": "AF",
  "Unicode dec": "129183",
  "Unicode hex": "1F89F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "176",
  "Dingbat hex": "B0",
  "Unicode dec": "129070",
  "Unicode hex": "1F82E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "177",
  "Dingbat hex": "B1",
  "Unicode dec": "129072",
  "Unicode hex": "1F830"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "178",
  "Dingbat hex": "B2",
  "Unicode dec": "129074",
  "Unicode hex": "1F832"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "179",
  "Dingbat hex": "B3",
  "Unicode dec": "129076",
  "Unicode hex": "1F834"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "180",
  "Dingbat hex": "B4",
  "Unicode dec": "129078",
  "Unicode hex": "1F836"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "181",
  "Dingbat hex": "B5",
  "Unicode dec": "129080",
  "Unicode hex": "1F838"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "182",
  "Dingbat hex": "B6",
  "Unicode dec": "129082",
  "Unicode hex": "1F83A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "183",
  "Dingbat hex": "B7",
  "Unicode dec": "129081",
  "Unicode hex": "1F839"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "184",
  "Dingbat hex": "B8",
  "Unicode dec": "129083",
  "Unicode hex": "1F83B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "185",
  "Dingbat hex": "B9",
  "Unicode dec": "129176",
  "Unicode hex": "1F898"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "186",
  "Dingbat hex": "BA",
  "Unicode dec": "129178",
  "Unicode hex": "1F89A"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "187",
  "Dingbat hex": "BB",
  "Unicode dec": "129177",
  "Unicode hex": "1F899"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "188",
  "Dingbat hex": "BC",
  "Unicode dec": "129179",
  "Unicode hex": "1F89B"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "189",
  "Dingbat hex": "BD",
  "Unicode dec": "129084",
  "Unicode hex": "1F83C"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "190",
  "Dingbat hex": "BE",
  "Unicode dec": "129086",
  "Unicode hex": "1F83E"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "191",
  "Dingbat hex": "BF",
  "Unicode dec": "129085",
  "Unicode hex": "1F83D"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "192",
  "Dingbat hex": "C0",
  "Unicode dec": "129087",
  "Unicode hex": "1F83F"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "193",
  "Dingbat hex": "C1",
  "Unicode dec": "129088",
  "Unicode hex": "1F840"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "194",
  "Dingbat hex": "C2",
  "Unicode dec": "129090",
  "Unicode hex": "1F842"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "195",
  "Dingbat hex": "C3",
  "Unicode dec": "129089",
  "Unicode hex": "1F841"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "196",
  "Dingbat hex": "C4",
  "Unicode dec": "129091",
  "Unicode hex": "1F843"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "197",
  "Dingbat hex": "C5",
  "Unicode dec": "129092",
  "Unicode hex": "1F844"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "198",
  "Dingbat hex": "C6",
  "Unicode dec": "129094",
  "Unicode hex": "1F846"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "199",
  "Dingbat hex": "C7",
  "Unicode dec": "129093",
  "Unicode hex": "1F845"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "200",
  "Dingbat hex": "C8",
  "Unicode dec": "129095",
  "Unicode hex": "1F847"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "201",
  "Dingbat hex": "C9",
  "Unicode dec": "11176",
  "Unicode hex": "2BA8"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "202",
  "Dingbat hex": "CA",
  "Unicode dec": "11177",
  "Unicode hex": "2BA9"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "203",
  "Dingbat hex": "CB",
  "Unicode dec": "11178",
  "Unicode hex": "2BAA"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "204",
  "Dingbat hex": "CC",
  "Unicode dec": "11179",
  "Unicode hex": "2BAB"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "205",
  "Dingbat hex": "CD",
  "Unicode dec": "11180",
  "Unicode hex": "2BAC"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "206",
  "Dingbat hex": "CE",
  "Unicode dec": "11181",
  "Unicode hex": "2BAD"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "207",
  "Dingbat hex": "CF",
  "Unicode dec": "11182",
  "Unicode hex": "2BAE"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "208",
  "Dingbat hex": "D0",
  "Unicode dec": "11183",
  "Unicode hex": "2BAF"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "209",
  "Dingbat hex": "D1",
  "Unicode dec": "129120",
  "Unicode hex": "1F860"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "210",
  "Dingbat hex": "D2",
  "Unicode dec": "129122",
  "Unicode hex": "1F862"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "211",
  "Dingbat hex": "D3",
  "Unicode dec": "129121",
  "Unicode hex": "1F861"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "212",
  "Dingbat hex": "D4",
  "Unicode dec": "129123",
  "Unicode hex": "1F863"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "213",
  "Dingbat hex": "D5",
  "Unicode dec": "129124",
  "Unicode hex": "1F864"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "214",
  "Dingbat hex": "D6",
  "Unicode dec": "129125",
  "Unicode hex": "1F865"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "215",
  "Dingbat hex": "D7",
  "Unicode dec": "129127",
  "Unicode hex": "1F867"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "216",
  "Dingbat hex": "D8",
  "Unicode dec": "129126",
  "Unicode hex": "1F866"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "217",
  "Dingbat hex": "D9",
  "Unicode dec": "129136",
  "Unicode hex": "1F870"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "218",
  "Dingbat hex": "DA",
  "Unicode dec": "129138",
  "Unicode hex": "1F872"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "219",
  "Dingbat hex": "DB",
  "Unicode dec": "129137",
  "Unicode hex": "1F871"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "220",
  "Dingbat hex": "DC",
  "Unicode dec": "129139",
  "Unicode hex": "1F873"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "221",
  "Dingbat hex": "DD",
  "Unicode dec": "129140",
  "Unicode hex": "1F874"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "222",
  "Dingbat hex": "DE",
  "Unicode dec": "129141",
  "Unicode hex": "1F875"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "223",
  "Dingbat hex": "DF",
  "Unicode dec": "129143",
  "Unicode hex": "1F877"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "224",
  "Dingbat hex": "E0",
  "Unicode dec": "129142",
  "Unicode hex": "1F876"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "225",
  "Dingbat hex": "E1",
  "Unicode dec": "129152",
  "Unicode hex": "1F880"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "226",
  "Dingbat hex": "E2",
  "Unicode dec": "129154",
  "Unicode hex": "1F882"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "227",
  "Dingbat hex": "E3",
  "Unicode dec": "129153",
  "Unicode hex": "1F881"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "228",
  "Dingbat hex": "E4",
  "Unicode dec": "129155",
  "Unicode hex": "1F883"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "229",
  "Dingbat hex": "E5",
  "Unicode dec": "129156",
  "Unicode hex": "1F884"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "230",
  "Dingbat hex": "E6",
  "Unicode dec": "129157",
  "Unicode hex": "1F885"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "231",
  "Dingbat hex": "E7",
  "Unicode dec": "129159",
  "Unicode hex": "1F887"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "232",
  "Dingbat hex": "E8",
  "Unicode dec": "129158",
  "Unicode hex": "1F886"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "233",
  "Dingbat hex": "E9",
  "Unicode dec": "129168",
  "Unicode hex": "1F890"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "234",
  "Dingbat hex": "EA",
  "Unicode dec": "129170",
  "Unicode hex": "1F892"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "235",
  "Dingbat hex": "EB",
  "Unicode dec": "129169",
  "Unicode hex": "1F891"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "236",
  "Dingbat hex": "EC",
  "Unicode dec": "129171",
  "Unicode hex": "1F893"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "237",
  "Dingbat hex": "ED",
  "Unicode dec": "129172",
  "Unicode hex": "1F894"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "238",
  "Dingbat hex": "EE",
  "Unicode dec": "129174",
  "Unicode hex": "1F896"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "239",
  "Dingbat hex": "EF",
  "Unicode dec": "129173",
  "Unicode hex": "1F895"
}, {
  "Typeface name": "Wingdings 3",
  "Dingbat dec": "240",
  "Dingbat hex": "F0",
  "Unicode dec": "129175",
  "Unicode hex": "1F897"
}];
exports.default = dingbats;
export default exports;